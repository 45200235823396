import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery";
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import RolesaudiencesService from "../../../services/RolesaudiencesService";
import { Button } from "react-bootstrap";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";

import { app_url } from "../../../services/http";
import { Dialog } from "primereact/dialog";
import {
  convertDateToTexte,
  getLastPartWithoutExtension,
  setDataTable,
} from "../../../utils/helpers";
import { getButtonConfig } from "../../../utils/ButtonUtils";

export default function RolesaudiencesIndex() {
  //##########################/// METHODES ##########################///
  const [rolesaudiences, setRolesaudiences] = useState([]);
  const [statuts, setStatuts] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [visible, setVisible] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const profils = useSelector((state) => state.auth.profil);

  //get liste roles audiences
  const fetchRolesaudiences = async () => {
    try {
      const response = await RolesaudiencesService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        setRolesaudiences(responseData.data);
        //console.log(responseData.data)
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  //get liste statuts
  const fetchStatuts = async () => {
    try {
      const response = await StatutsService.index(token);
      const statutsData = response.data.data;
      setStatuts(statutsData);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des parties membres :",
        error
      );
    }
  };

  //redirection sur la page edit sans afficher les id dans l'url
  const goToEdit = (param) => {
    //navigate(myroutes.rolesaudiences_edit, { state: { ref: param } })
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        RolesaudiencesService.delete(param, token)
          .then((response) => {
            if (response.data.status != true) {
              Swal.fire({
                icon: "error",
                text: response.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              fetchRolesaudiences();
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.response.data.message,
            });
            console.log(e);
          });
      }
    });
  };

  const handleDontDelete = () => {
    Swal.fire({
      icon: "warning",
      text: "Vous ne pouvez pas supprimer cet élément",
    });
  };

  const lauchTelechargement = (path) => {
    const url = `${app_url}/storage/${path}`;
    const link = document.createElement("a");
    link.href = url;

    // Trouver l'index de la dernière barre oblique "/"
    const lastSlashIndex = path.lastIndexOf("/");

    // Extraire la partie après la dernière barre oblique
    let lastPart = path.substring(lastSlashIndex + 1);

    link.download = lastPart; // Ajoutez un nom de fichier si nécessaire
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchRolesaudiences();
    fetchStatuts();
    $("#datesaudiences-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <BreadcrumbComponent
              params={{
                isParametre: false,
                libelle: "Rôles d'audience",
                title: "Audiences",
              }}
              routeName={myroutes.rolesaudiences_index}
            ></BreadcrumbComponent>

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  Liste des rôles d'audiences
                  {(profils.includes("GEC") ||
                    profils.includes("AGEC") ||
                    profils.includes("GCH") ||
                    profils.includes("ADMIN") ||
                    profils.includes("SUPERADMIN")) && (
                    <>
                      <Link
                        className={`btn btn-sm btn-${
                          getButtonConfig("CREATE")?.color
                        } mx-3`}
                        to={myroutes.rolesaudiences_create}
                        title="Ajouter"
                      >
                        <i className={`fa ${getButtonConfig("CREATE")?.icon}`}></i>&nbsp;Ajouter
                      </Link>
                    </>
                  )}
                </h3>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2 ">
                      <tr>
                        <th className="text-center">N°</th>
                        <th className="text-center">Date d'audience</th>
                        <th className="text-center">Chambre</th>
                        <th className="text-center">Rôle d'audience</th>
                        <th className="text-center">Statut</th>
                        <th className="text-center">Date MàJ</th>
                        {(profils.includes("GEC") ||
                          profils.includes("AGEC") ||
                          profils.includes("ADMIN") ||
                          profils.includes("SUPERADMIN")) && (
                          <th className="text-center">Activité</th>
                        )}
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider text-center"
                      style={{ textAlign: "left" }}
                    >
                      {rolesaudiences.length > 0 &&
                        rolesaudiences.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell text-capitalize">
                              {convertDateToTexte(
                                dayjs(item.periode).format("YYYY-MM-DD")
                              )}
                            </td>
                            <td className="cell">
                              {item.get_chambre?.libelle}
                            </td>
                            <td className="cell">
                              <button
                                className="btn btn-sm btn-light"
                                onClick={() => lauchTelechargement(item.path)}
                                style={{ border: "none" }}
                              >
                                <i
                                  className="fa fa-file-word h2 text-primary"
                                  title="Télécharger le document"
                                ></i>
                              </button>
                            </td>
                            <td className="cell">
                              <span
                                className={`badge ${
                                  item.get_statut?.libelle === "ACTIF"
                                    ? "text-bg-success"
                                    : item.get_statut?.libelle === "BROUILLON"
                                    ? "text-bg-secondary"
                                    : "text-bg-danger"
                                }`}
                              >
                                {item.get_statut.libelle}
                              </span>
                            </td>
                            <td className="cell">
                              <span>
                                {dayjs(item.updated_at).format(
                                  "YYYY-MM-DD H:m:s"
                                )}
                              </span>
                            </td>
                            {(profils.includes("GEC") ||
                              profils.includes("AGEC") ||
                              profils.includes("ADMIN") ||
                              profils.includes("SUPERADMIN")) && (
                              <>
                                <td className="cell">
                                  {item.get_statut.libelle === "BROUILLON" ? (
                                    <Button
                                      className={`btn btn-sm btn-${
                                        getButtonConfig("DELETE")?.color
                                      }`}
                                      onClick={() => onDelete(item.ref)}
                                      title="Supprimer"
                                    >
                                      <i className={`fa ${
                                    getButtonConfig("DELETE")?.icon
                                  }`}></i>
                                    </Button>
                                  ) : null}
                                  {/***<Button className="btn btn-sm btn-dark" onClick={() => handleDontDelete(item.ref)} title="Vous ne pouvez pas supprimer cet élément">
                                                                    <i class="bi bi-x-circle"></i></Button> */}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
