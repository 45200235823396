import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import AssujettiesService from "../services/AssujettiesService";
import CivilitesService from "../services/CivilitesService";
import { MultiSelect } from "primereact/multiselect";
import { afficherErrorToast, afficherMessagesSuccess, afficherOneMessageError } from "../utils/helpers";
import { Dropdown } from "primereact/dropdown";
import StatutsjuridiquesService from "../services/StatutsjuridiquesService";
import { Dialog } from "primereact/dialog";
import TypeActeursService from "../services/TypeActeursService";

const IntimeForm = ({ index, sendData }) => {
  const token = useSelector((state) => state.auth.token);
  const intimeSaisie = useSelector((state) => state.auth.intimes);

  const [is_acteur, setIsActeur] = useState(0);
  const [civilites, setCivilites] = useState([]);
  const [civilite_id, setCivilite_id] = useState("");
  const [type_personne, setType_personne] = useState("PP");
  const [raison_sociale, setRaison_sociale] = useState("");
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [prenoms, setPrenoms] = useState("");
  const [telephone, setTelephone] = useState("");
  const [adresse, setAdresse] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [rccm, setRccm] = useState("");
  const [ifu, setIfu] = useState("");
  const [npi, setNPI] = useState("");
  const [validationError, setValidationError] = useState({});
  const [selectedOptionRepresentePar, setSelectedOptionRepresentePar] = useState([]);
  const [assujetties_acteurs, setAssujetties_acteurs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [intime, setIntime] = useState(1);
  const [showSection, setShowSection] = useState(false);
  const [selectedAssujetties, setSelectedAssujetties] = useState("");
  const [assujetties_not_acteur, setAssujettiesNotActeur] = useState([]);
  const [statuts_juridiques, setStatutsJuridiques] = useState([]);
  const [statut_juridique_id, setStatutJuridiqueId] = useState("");
  const [type_acteurs, setTypeacteurs] = useState([]);
  const [type_acteur_id, setTypeacteur_id] = useState('');
  const [showModalAvocat, setShowModalAvocat] = useState(false);

  const [avocatForm, setAvocatForm] = useState({
    nom: "",
    prenoms: "",
    telephone: "",
    email: "",
    adresse: "",
    civilite_id: "",
    type_personne: "PP",
    raison_sociale: "",
    rccm: "",
    ifu: "",
    npi: "",
    type_acteur_id: ""
  });


  const [modal_type_personne, setModalType_personne] = useState("PP");


  const handleShowModal = () => { setShowModalAvocat(true); setIsActeur(1) } // Fonction pour afficher le modal
  const handleCloseModal = () => { setShowModalAvocat(false); setIsActeur(0) }; // Fonction pour fermer le modal

  const appelantSaisie = useSelector((state) => state.auth.appelants);

  //declaration des variables pour le form d'enregistrement
  const [addFormData, setAddFormData] = useState(intimeSaisie);

  const handleChangeData = (e) => {
    setAddFormData({ ...addFormData, [e.target.name]: e.target.value });
  };

   //Fonction pour le modal d'ajout d'avocat
   const handleChangeAvocatForm = (e) => {
    if (e.target.name == 'type_personne') {
      setAvocatForm({ ...avocatForm, [e.target.name]: e.target.value == "PP" ? "PM" : "PP" });
      console.log(e.target.value)
    } else {
      setAvocatForm({ ...avocatForm, [e.target.name]: e.target.value });
    }
  };


  // Fonction pour formater la date au format requis
  function getFormattedDate() {
    const date = new Date();
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
    let day = date.getDate().toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
    return `${year}-${month}-${day}`; // Retourne la date au format YYYY-MM-DD
  }

  const handleOptionChange = (value) => {
    setIsActeur(value);
    setTypeacteur_id("")
  };
  // Définition de la fonction pour mettre à jour les représentants sélectionnés
  const handleSelectedRepresenteParChange = (selectedOptionRepresentePar) => {
    // setIsAvocat(1);
    setSelectedOptionRepresentePar(selectedOptionRepresentePar);
  };

  const handleChange = (value, country) => {
    setTelephone(value);
    setTelephoneError("");
  };


  const handleChangeAvocatTel = (value, country) => {
    setAvocatForm({ ...avocatForm, ['telephone']: value });
    setTelephoneError("");
  };

  
  //get liste civilites
  const fetchCivilites = async () => {
    await CivilitesService.index(token)
      .then((response) => {
        setCivilites(response.data.data);

      })
      .catch((error) => {
        console.log(error);
      });
  };


  const fetchAssujetties_acteurs = async () => {
    try {
      const response = await AssujettiesService.index_acteurs(token);
      //Get only Avocat
      const filteredData = response.data.data.filter((item) => item.get_type_acteur?.libelle === "Avocat")

      const assujetieFiltree = filteredData.filter(itemA => {
        // Vérifier si l'ID de l'élément actuel est inclus dans selectedOptionRepresentePar
        return !appelantSaisie.some(itemB =>
          itemB.selectedOptionRepresentePar.includes(itemA.id.toString())
        );
      });

      setAssujetties_acteurs(assujetieFiltree);
      //console.log(assujetieFiltree)
    } catch (error) {
      console.log(error);
    }
  };

  const dataassujetties_acteurs = assujetties_acteurs.map((item) => {
    return {
      label:
        item.type_personne === "PP"
          ? `Me ${item.nom} ${item.prenoms}`
          : item.raison_sociale,
      value: item.id,
    };
  });

  const fetchAssujettiesNotActeur = async () => {
    await AssujettiesService.index(token)
      .then((response) => {
        //Afficher les assujetties qui sont pas des acteurs
        const filteredData = response.data.data.filter((opt) => opt.is_acteur === parseInt(0));
        //Filtrer les données pour enlever celles déjà sélectionnées dans appelant
        const assujetieFiltree = filteredData.filter(itemA => !appelantSaisie.some(itemB => parseInt(itemA.id) === parseInt(itemB.assujettie_selected)));

        const data = assujetieFiltree.map((item) => {
          return {
            name: item.raison_sociale,
            id: item.id
          }
        })
        //console.log(appelantSaisie)
        //console.log("==================")
        //console.log(assujetieFiltree)
        setAssujettiesNotActeur(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSaveProgess = async (index) => {
    //e.preventDefault();
    setLoading(true); // Début du chargement

    try {
      if (!selectedAssujetties) {
        // Validation du numéro de téléphone
        /*if (!/^\+?\d*$/.test(telephone)) {
          setTelephoneError("Le numéro de téléphone n'est pas valide.");
          setLoading(false); // Fin du chargement en cas d'erreur de validation
          return;
        }

        if (telephone === "") {
          setTelephoneError("Veuillez saisir votre téléphone");
          setLoading(false); // Fin du chargement en cas d'erreur de validation
          return;
        }*/
      }
      const assujettie_selected = selectedAssujetties.id;

      let formData = {
        email, telephone, adresse, rccm, ifu, civilite_id, type_personne, raison_sociale,
        nom, prenoms, is_acteur, intime, npi, selectedOptionRepresentePar, assujettie_selected, statut_juridique_id
      }
      //console.log(formData)
      sendData({ form: formData, position: index })
      afficherMessagesSuccess("Intimé-e " + (++index) + " enregistré avec succès");

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        text: error.response.data.data
      });
      console.error(error);
    } finally {
      setLoading(false); // Fin du chargement après le traitement
    }
  };


  const fetchStatutJuridique = async () => {
    try {
      const response = await StatutsjuridiquesService.index(token);
      if (response.status == 200) {
        setStatutsJuridiques(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //Save new avocat
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Début du chargement

    try {
      // Validation du numéro de téléphone
      /*if (!/^\+?\d*$/.test(avocatForm.telephone)) {
        afficherErrorToast("Le numéro de téléphone n'est pas valide.");
        setLoading(false); // Fin du chargement en cas d'erreur de validation
        return;
      }

      if (avocatForm.telephone === "") {
        afficherErrorToast("Veuillez saisir votre téléphone");
        setLoading(false); // Fin du chargement en cas d'erreur de validation
        return;
      }*/

      if (avocatForm.type_acteur_id == "") {
        afficherErrorToast("Veuillez sélectionner le type d'acteur");
        setLoading(false); // Fin du chargement en cas d'erreur de validation
        return;
      }

      setShowModalAvocat(false);
      const formData = new FormData();

      // Ajoutez les champs communs
      formData.append("email", avocatForm.email);
      formData.append("telephone", avocatForm.telephone);
      formData.append("adresse", avocatForm.adresse);
      formData.append("rccm", avocatForm.rccm);
      formData.append("ifu", avocatForm.ifu);
      formData.append("civilite_id", (avocatForm.civilite_id !== undefined) ? avocatForm.civilite_id : "");

      // Ajoutez le champ spécifique pour le type de personne
      formData.append("type_personne", modal_type_personne);

      // Ajoutez les champs spécifiques en fonction du type de personne
      if (modal_type_personne === "PM") {
        formData.append("raison_sociale", avocatForm.raison_sociale);
      } else {
        formData.append("raison_sociale", avocatForm.raison_sociale);
        formData.append("nom", avocatForm.nom);
        formData.append("prenoms", avocatForm.prenoms);
      }
      formData.append("is_acteur", 1);
      formData.append("type_acteur_id", avocatForm.type_acteur_id);

      // Enregistrez le formulaire
      const response = await AssujettiesService.store(formData, token);
      if (response.data.status == true) {
        //Fermer le modal
        handleCloseModal();
        //console.log(response.data)
        // Affichez un message de succès
        Swal.fire({
          icon: "success",
          title: "Bravo !",
          text: response.data.message,
        });

        // Réinitialisez les données du formulaire
         /*setRaison_sociale("");
        setNom("");
        setPrenoms("");
        setEmail("");
        setTelephone("");
        setAdresse("");
        setRccm("");
        setIfu("");
        setType_personne("PM");
        setIsActeur(0);
        setTypeacteur_id("");*/
        setAvocatForm({})

        //Mettre à jour la liste des acteurs affichés
        fetchAssujetties_acteurs();
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.message + ' ' + response.data.data,
        });
      }

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous avez mal renseigné les champs ou cet élément existe déjà dans la base de données'
      });
      console.error(error);
    } finally {
      setLoading(false); // Fin du chargement après le traitement
      //handleCloseModal();
    }
  };

  //Liste des types d'acteurs
  const fetchTypeacteurs = async () => {
    await TypeActeursService.index(token).then((response) => {
      if (response.status === 200) {
        setTypeacteurs(response.data.data.filter((option) => option.libelle === "Avocat"))
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    setCurrentDate(getFormattedDate());
    fetchCivilites();
    fetchAssujetties_acteurs();
    fetchAssujettiesNotActeur();
    fetchStatutJuridique();
    fetchTypeacteurs();


  }, [appelantSaisie]);
  return (
    <div>
      <Row>
        {Object.keys(validationError).length > 0 && (
          <div className="row">
            <div className="col-12">
              <div className="alert alert-danger">
                <ul className="mb-0">{<li>{validationError}</li>}</ul>
              </div>
            </div>
          </div>
        )}
      </Row>

      {showSection === false && <Row className="mb-4 mx-3 settings-form">
        <p className="mb-2" style={{ fontSize: "16px", textAlign: "left" }}>Si vous ne trouvez pas l'intimé-e-s recherché(e) dans la liste, vous pouvez la/le créer en cliquant sur le bouton
          <span onClick={() => setShowSection(true)} className="text-primary fw-bold" style={{ textDecoration: "underline" }}> Créer</span>.</p>
        <Dropdown value={selectedAssujetties} onChange={(e) => setSelectedAssujetties(e.value)} options={assujetties_not_acteur} optionLabel="name" placeholder="Rechercher..."
          filter className="" />

        <div className="row mt-3 g-3 border-top">
          <p className="" style={{ fontSize: "16px", textAlign: "left" }}>Si vous ne trouvez pas l'avocat recherché dans la liste, vous pouvez l'ajouter en cliquant sur le bouton
            &nbsp;<span onClick={() => handleShowModal()} className="text-primary fw-bold" style={{ textDecoration: "underline" }}>Créer un nouvel avocat</span>.</p>
        </div>

        <div className="row mb-0  align-items-center justify-content-between">
          <div
            className="card bg-secondary p-1"
            style={{
              color: "white",
              backgroundColor: "white",
              border: "1px solid white",
            }}
          >
            <Form.Group className="mb-2 p-2">
              <Form.Label className="fw-bold">
                Avocat (s) <i className="text-danger"></i>
              </Form.Label>
              <MultiSelect
                optionLabel="label"
                placeholder="Rechercher..."
                maxSelectedLabels={10}
                className="w-full md:w-25rem"
                value={selectedOptionRepresentePar}
                onChange={(e) => { handleSelectedRepresenteParChange(e.value) }}
                options={dataassujetties_acteurs}
                style={{ width: "100%" }}
                filter
                display="chip"
                filterPlaceholder="Rechercher"
              />
            </Form.Group>
          </div>
        </div>
      </Row>}

      {showSection === true &&
        <div className="mx-3 settings-form" style={{ textAlign: "left" }} >
          <Row className="mb-4 mx-3 settings-form">
            <p className="mb-2" style={{ fontSize: "16px", textAlign: "left" }}>Pour rechercher l'intimé-e-s dans la liste, vous pouvez cliquer sur le bouton
              <span onClick={() => setShowSection(false)} className="text-primary fw-bold" style={{ textDecoration: "underline" }}> Rechercher</span>.</p>
          </Row>
          <Row>
            <Col md={12} sm={12} className="form-group">
              <Form.Group className="mb-3 d-flex">
                <div className="d-flex align-items-center">
                  <Form.Check className="me-3" type="radio" id="personnePhysiqueOui"
                    label="Personne Physique" name="personnePhysique" checked={type_personne === "PP"}
                    onChange={() => setType_personne("PP")} />
                  <Form.Check type="radio" id="personnePhysiqueNon" label="Personne Morale"
                    name="personnePhysique" checked={type_personne === "PM"} onChange={() => setType_personne("PM")} />
                </div>
              </Form.Group>

            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6} className="form-group " style={{ display: type_personne === 'PP' ? 'none' : 'block' }}>
              <Form.Group className="mb-3" >
                <Form.Label className="fw-bold">
                  Dénomination sociale <i className="text-danger">*</i>
                </Form.Label>
                <Form.Control className="border border rounded-2 control-label" onChange={(e) => setRaison_sociale(e.target.value)}
                  value={raison_sociale} placeholder="" name="raison_sociale" aria-label="raison_sociale"
                  maxLength={100} minLength={3} type="text" />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} className="form-group " style={{ display: type_personne === 'PP' ? 'none' : 'block' }}>
              <Form.Group className="mb-3" >
                <Form.Label className="fw-bold">
                  Forme Juridique <i className="text-danger"></i>
                </Form.Label>
                <Form.Select
                  className="control-label"
                  id="statut_juridique"
                  name="statut_juridique"
                  value={statut_juridique_id} onChange={(e) => { setStatutJuridiqueId(e.target.value); }}
                >
                  <option value="" selected>--</option>
                  {statuts_juridiques.length > 0 && statuts_juridiques.map((item, index) => (
                    <option key={index} value={item.id}>{item.libelle}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {type_personne === "PP" && (
              <>
                <Col md={2} sm={2} className="formx-groupx">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">
                      Civilité <i className="text-danger">*</i>
                    </Form.Label>
                    <Form.Select className="control-label" id="civilite_id" name="civilite_id"
                      value={civilite_id} onChange={(e) => { setCivilite_id(e.target.value); }}>
                      <option value="" selected>
                        --
                      </option>
                      {civilites.length > 0 &&
                        civilites.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.libelle}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={5} sm={5} className="form-group">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">
                      Nom <i className="text-danger">*</i>
                    </Form.Label>
                    <Form.Control className="border border rounded-2 control-label" onChange={(e) => setNom(e.target.value)}
                      value={nom} placeholder="" name="nom" aria-label="nom" maxLength={100} minLength={2} type="text" required />
                  </Form.Group>
                </Col>
                <Col md={5} sm={5} className="form-group">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">
                      Prénom (s) <i className="text-danger">*</i>
                    </Form.Label>
                    <Form.Control className="border border rounded-2 control-label" onChange={(e) => setPrenoms(e.target.value)}
                      value={prenoms} placeholder="" name="prenoms" aria-label="prenoms" maxLength={100}
                      minLength={3} type="text" required />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} className="form-group">
                  <Form.Group className="mb-3" style={{ displayx: type_personne === 'PP' ? 'none' : 'block' }}>
                    <Form.Label className="fw-bold">
                      Dénomination sociale <i className="text-danger"></i>
                    </Form.Label>
                    <Form.Control className="border border rounded-2 control-label" onChange={(e) => setRaison_sociale(e.target.value)}
                      value={raison_sociale} placeholder="Renseigner la dénomination sociale s'il y a lieu"
                      name="raison_sociale" aria-label="raison_sociale" maxLength={100} minLength={3} type="text" />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} className="form-group">
                  <Form.Group className="mb-3" style={{ displayx: type_personne === 'PP' ? 'none' : 'block' }}>
                    <Form.Label className="fw-bold">
                      NPI <i className="text-danger"></i>
                    </Form.Label>
                    <Form.Control className="border border rounded-2 control-label" onChange={(e) => setNPI(e.target.value)}
                      value={npi} placeholder=""
                      name="npi" aria-label="npi" maxLength={14} minLength={3} type="number" />
                  </Form.Group>
                </Col>
              </>
            )}

            <Col md={6} sm={6} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">RCCM</Form.Label>
                <Form.Control className="border border rounded-2 control-label" onChange={(e) => setRccm(e.target.value)}
                  value={rccm} placeholder="" name="rccm" aria-label="rccm" maxLength={100} minLength={3} type="text" />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">IFU <i className="text-danger"></i></Form.Label>
                <Form.Control className="border border rounded-2 control-label" onChange={(e) => setIfu(e.target.value)}
                  value={ifu} placeholder="" name="ifu" aria-label="ifu" maxLength={14} minLength={3} type="number" />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">
                  Email <i className="text-danger"></i>
                </Form.Label>
                <Form.Control className="border border rounded-2 control-label" onChange={(e) => setEmail(e.target.value)}
                  value={email} placeholder="" name="email" aria-label="email" maxLength={100}
                  minLength={3} type="email" />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">
                  Téléphone <i className="text-info bi bi-question-circle-fill" title="Veuillez choisir le drapeau et compléter votre numéro"></i>
                </Form.Label>
                <div
                ><PhoneInput defaultCountry="BJ" value={telephone} onChange={handleChange}
                  inputClass={`form-input`} containerStyle={{ border: "", borderRadius: "6px", height: "50px" }}
                  inputStyle={{ width: "80%", border: "none", outline: "none", height: "100px" }}
                  placeholder="61 00 00 00" />
                </div>
              </Form.Group>
            </Col>
            <Col md={12} sm={12} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">
                  Adresse<i className="text-danger"></i>
                </Form.Label>
                <textarea value={adresse} name="adresse" id="" cols="20" onChange={(e) => setAdresse(e.target.value)} rows="3" className="form-control summernote border border rounded-2"
                  placeholder="Boîte postale, localisation, ville, ..."
                  maxLength={100}></textarea>

              </Form.Group>
            </Col>
          </Row>


          <div className="row mt-3 g-3 border-top">
            <p className="" style={{ fontSize: "16px", textAlign: "left" }}>Si vous ne trouvez pas l'avocat recherché dans la liste, vous pouvez l'ajouter en cliquant sur le bouton
              &nbsp;<span onClick={() => handleShowModal()} className="text-primary fw-bold" style={{ textDecoration: "underline" }}>Créer un nouvel avocat</span>.</p>
          </div>

          <div className="row g-3 mb-0  align-items-center justify-content-between">
            <div className="card bg-secondary p-1" style={{ color: "white", backgroundColor: "white", border: "1px solid white", }} >
              <Form.Group className="mb-2 p-2">
                <Form.Label className="fw-bold">
                  Avocat (s) <i className="text-danger"></i>
                </Form.Label>
                <MultiSelect optionLabel="label" placeholder="Rechercher..." display="chip" maxSelectedLabels={10} className="w-full md:w-25rem"
                  value={selectedOptionRepresentePar} onChange={(e) => {
                    handleSelectedRepresenteParChange(e.value);
                  }} options={dataassujetties_acteurs}
                  style={{ width: "100%" }} filter filterPlaceholder="Rechercher" />
              </Form.Group>
            </div>
          </div>

        </div>}


      {/* Modal */}
      <Dialog
        header={"Enregistrement d'un avocat"}
        visible={showModalAvocat}
        maximizable
        onHide={handleCloseModal}
        style={{ width: "700px", borderBottom: "1px solid grey", color: "GrayText" }}
        headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
      >

        <Form
          className="mx-3 settings-form p-4"
          style={{ textAlign: "left" }}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col md={12} sm={12} className="form-group">
              <Form.Group className="mb-3 d-flexx">
                <Form.Label
                  className="fw-bold mb-3"
                  style={{
                    fontSize: "18px",
                    color: "tomato",
                    fontStyle: "italicx",
                  }}
                >
                  Etes-vous une personne physique ?{" "}
                  <i className="text-danger">*</i>
                </Form.Label>
                <Form.Check
                  type="switch"
                  id="type_personneSwitch"
                  label={modal_type_personne === "PP" ? "Oui" : "Non"}
                  onChange={() =>
                    setModalType_personne(
                      modal_type_personne === "PP" ? "PM" : "PP"
                    )
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12} className="form-group" style={{ display: modal_type_personne === 'PP' ? 'none' : 'block' }}>
              <Form.Group className="mb-3" >
                <Form.Label className="fw-bold">
                  Dénomination sociale <i className="text-danger">*</i>
                </Form.Label>
                <Form.Control
                  className="border border rounded-2"
                  onChange={(e) => handleChangeAvocatForm(e)}
                  value={avocatForm.raison_sociale}
                  placeholder=""
                  name="raison_sociale"
                  aria-label="raison_sociale"
                  maxLength={100}
                  minLength={3}
                  type="text"
                // hidden={type_personne === "PP"}
                />
              </Form.Group>
            </Col>
            {modal_type_personne === "PP" && (
              <>

                <Col md={2} sm={2} className="formx-groupx">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">
                      Civilité <i className="text-danger">*</i>
                    </Form.Label>
                    <Form.Select
                      className=""
                      id="civilite_id"
                      name="civilite_id"
                      value={avocatForm.civilite_id}
                      // required
                      onChange={(e) => handleChangeAvocatForm(e)}
                    >
                      <option value="" selected>
                        --
                      </option>
                      {civilites.length > 0 &&
                        civilites.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.libelle}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={5} sm={5} className="form-group">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">
                      Nom <i className="text-danger">*</i>
                    </Form.Label>
                    <Form.Control
                      className="border border rounded-2"
                      onChange={(e) => handleChangeAvocatForm(e)}
                      value={avocatForm.nom}
                      placeholder=""
                      name="nom"
                      aria-label="nom"
                      maxLength={100}
                      minLength={2}
                      type="text"                
                      style={{textTransform: "uppercase"}}
                    />
                  </Form.Group>
                </Col>
                <Col md={5} sm={5} className="form-group">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">
                      Prénom (s) <i className="text-danger">*</i>
                    </Form.Label>
                    <Form.Control
                      className="border border rounded-2"
                      onChange={(e) => handleChangeAvocatForm(e)}
                      value={avocatForm.prenoms}
                      placeholder=""
                      name="prenoms"
                      aria-label="prenoms"
                      maxLength={100}
                      minLength={3}
                      type="text"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={12} sm={12} className="form-group">
                  <Form.Group className="mb-3" style={{ displayx: modal_type_personne === 'PP' ? 'none' : 'block' }}>
                    <Form.Label className="fw-bold">
                      Dénomination sociale <i className="text-danger"></i>
                    </Form.Label>
                    <Form.Control
                      className="border border rounded-2"
                      onChange={(e) => handleChangeAvocatForm(e)}
                      value={avocatForm.raison_sociale}
                      placeholder="Renseigner la dénomination sociale s'il y a lieu"
                      name="raison_sociale"
                      aria-label="raison_sociale"
                      maxLength={100}
                      minLength={3}
                      type="text"
                    // disabled={modal_type_personne === "PP"}
                    />
                  </Form.Group>
                </Col>
              </>
            )}

            <Col md={6} sm={6} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">RCCM </Form.Label>
                <Form.Control
                  className="border border rounded-2"
                  onChange={(e) => handleChangeAvocatForm(e)}
                  value={avocatForm.rccm}
                  placeholder=""
                  name="rccm"
                  aria-label="rccm"
                  maxLength={100}
                  minLength={3}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">IFU <i className="text-danger"></i></Form.Label>
                <Form.Control
                  className="border border rounded-2"
                  onChange={(e) => handleChangeAvocatForm(e)}
                  value={avocatForm.ifu}
                  placeholder=""
                  name="ifu"
                  aria-label="ifu"
                  maxLength="14"
                  minLength="3"
                  type="number"
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">
                  Email <i className="text-danger"></i>
                </Form.Label>
                <Form.Control
                  className="border border rounded-2"
                  onChange={(e) => handleChangeAvocatForm(e)}
                  value={avocatForm.email}
                  placeholder=""
                  name="email"
                  aria-label="email"
                  maxLength={100}
                  minLength={3}
                  type="email"
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">
                  Téléphone <i className="text-info bi bi-question-circle-fill" title="Veuillez choisir le drapeau et compléter votre numéro"></i>
                </Form.Label>
                <div
                ><PhoneInput
                    defaultCountry="BJ"
                    value={avocatForm.telephone}
                    onChange={handleChangeAvocatTel}
                    inputClass={`form-input`}
                    containerStyle={{
                      border: "",
                      borderRadius: "6px",
                      height: "50px"
                    }}
                    inputStyle={{
                      width: "80%",
                      border: "none",
                      outline: "none",
                      height: "100px"
                    }}
                    placeholder="61 00 00 00"
                  />

                </div>

              </Form.Group>
            </Col>
            <Col md={12} sm={12} className="form-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">
                  Adresse<i className="text-danger"></i>
                </Form.Label>
                <textarea value={avocatForm.adresse} name="adresse" id="" cols="30" onChange={(e) => handleChangeAvocatForm(e)} rows="3" className="form-control summernote border border rounded-2"
                  placeholder="Boîte postale, localisation, ville, ..."
                  maxLength={100}></textarea>

              </Form.Group>
            </Col>
          </Row>
          <Row>

            {is_acteur == 1 && <Col md={12} sm={12} className="formx-group">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">
                  Type d'acteur
                  <i className="text-danger">*</i>
                </Form.Label>
                <Form.Select
                  className=""
                  id="type_acteur_id"
                  name="type_acteur_id"
                  value={avocatForm.type_acteur_id}
                  // required
                  onChange={(e) => handleChangeAvocatForm(e)}
                >
                  <option value="" selected>
                  -- Sélectionnez une valeur --
                  </option>
                  {type_acteurs.length > 0 &&
                    type_acteurs.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.libelle}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            }

          </Row>
          <div className="p-dialog-footer mt-3">
            <Button type="submit" variant="warning me-3">Enregistrer</Button> 
            <Button variant="secondary" onClick={handleCloseModal}>Annuler</Button>
          </div>

        </Form>

      </Dialog>


      <div className="py-3 row">
        <Button className="mb-3" variant="success" onClick={() => handleSaveProgess(index)} >Valider</Button>
      </div>
    </div>
  );
};

export default IntimeForm;
