import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { myroutes } from "../../../routes/webroute";
import { RadioButton } from 'primereact/radiobutton';
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ModesPaiementsService from "../../../services/ModesPaiementsService";
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener, } from "kkiapay";
import { ProgressSpinner } from "primereact/progressspinner";
import Swal from "sweetalert2";
import LayoutActeur from "../inc/layout_acteur";
import DemandeArretsService from "../../../services/DemandeArretsService";
import { getPaiementConfig } from "../../../utils/helpers";

export default function DemandeArretsCreate() {
    const token = useSelector((state) => state.auth.token);
    const [email, setEmail] = useState([]);
    const [telephone, setTelephone] = useState("");
    const [nom, setNom] = useState("");
    const [prenoms, setPrenoms] = useState("");
    const [num_arret, setNumArret] = useState("");
    const [nature_affaire, setNatureAffaire] = useState("");
    const [reference_dossier, setReferenceDossier] = useState("");
    const [date_arret, setDateArret] = useState("");
    const [telephoneError, setTelephoneError] = useState("");
    const navigate = useNavigate();
    const [showModePaiement, setshowModePaiement] = useState(false);
    const [modespaiements, setModesPaiements] = useState([]);
    const [mode_paiement_id, setModePaiement_Id] = useState("");
    const [montant_payer, setMontantPayer] = useState(5000);
    const auth = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false); // État pour le chargement

    //get liste mode de paiement
    const fetchModepaiements = async () => {
        await ModesPaiementsService.index(token)
            .then((response) => {
                //Récupérer uniquement les modes de paiements actif
                setModesPaiements(response.data.data.filter((mode) => mode.statut_id === 1))
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleChange = (value, country) => {
        setTelephone(value);
        setTelephoneError("");
    };

    const handleSetModePaiement = (e) => {
        setModePaiement_Id(e.target.value)
    }

    
    //Get kkiapay reponse after paiement success
    const successHandler = async (response) => {

        setLoading(true); // Début du chargement

        //Recuperation du code de la transaction de kkiapay
        var code_transaction = response.transactionId
        // Enregistrer le paiement
        let paiementsInfo = { montant: montant_payer, reference_dossier: response.data.reference_dossier, telephone: auth.user.telephone, modepaiement_id: response.data.mode_paiement_id }
        DemandeArretsService.storePaiement(code_transaction, paiementsInfo, auth.token).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: 'Paiement effectué avec succès !',
                    //text: res.data.data.transaction.message,
                });
                // Actualiser la page
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            } else if (res.data.data.echec) {
                setLoading(false); // Fin du chargement en cas d'erreur de validation

                Swal.fire({
                    icon: 'warning',
                    title: 'Une erreur s\'est produite !',
                    text: res.data.data.echec,
                });
            } else if (res.data.erreur) {
                setLoading(false); // Fin du chargement en cas d'erreur de validation

                Swal.fire({
                    icon: 'error',
                    title: 'Erreur!',
                    text: res.data.message,
                });
            }
        }).catch(error => {
            setLoading(false); // Fin du chargement en cas d'erreur de validation
            console.log("Error", error);
        });

    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        //setshowModePaiement(true)
        setLoading(true); // Début du chargement

        try {
            // Validation du numéro de téléphone
            if (!/^\+?\d*$/.test(telephone)) {
                setTelephoneError("Le numéro de téléphone n'est pas valide.");
                setLoading(false); // Fin du chargement en cas d'erreur de validation
                return;
            }

            if (telephone === "") {
                setTelephoneError("Veuillez saisir votre téléphone");
                setLoading(false); // Fin du chargement en cas d'erreur de validation
                return;
            }

            const formData = new FormData();

            // Ajoutez les champs communs
            formData.append("email", email);
            formData.append("telephone", telephone);
            formData.append("nom", nom);
            formData.append("prenoms", prenoms);
            formData.append("num_arret", num_arret);
            formData.append("nature_affaire", nature_affaire);
            formData.append("date", date_arret);
            formData.append("reference_dossier", reference_dossier);

            const response = await DemandeArretsService.store(formData, token);
            if (response.status === 200) {
                setLoading(false);
                //Lancer la fenetre de paiement
                if (showModePaiement == true && mode_paiement_id == '') {
                    return false
                }
                //Afficher le hook de paiement
                var config = getPaiementConfig();
                openKkiapayWidget({
                    amount: montant_payer,
                    position: "center",
                    data: { reference_dossier, mode_paiement_id },
                    name: auth.user.fullname,
                    theme: "red",
                    api_key: config.key,
                    sandbox: config.isSandbox,
                    email: auth.user.email,
                    //phone: auth.user.telephone,
                });

            } else {
                Swal.fire({
                    icon: "error",
                    text: response.data.error,
                });
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: error.response.data.message
            });
            console.error(error);
        } finally {
            setLoading(false); // Fin du chargement après le traitement
        }
    };


    useEffect(() => {
        fetchModepaiements();
        addKkiapayListener('success', successHandler)
        return () => {
            removeKkiapayListener('success', successHandler)
        };
    }, []);

    return (
        <LayoutActeur>
            <main id="main" className="main ">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">
                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to={myroutes.dashboard_acteur}>Accueil</Link>
                                            </li>
                                            <li className="breadcrumb-item active">Demande de copie d'arrêt </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Enregistrement </h1>
                            </div>
                        </div>
                        {/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">

                                <Form
                                    className="mx-3 settings-form p-4"
                                    style={{ textAlign: "left" }}
                                    onSubmit={handleSubmit}
                                >
                                    <Row>

                                        <Col md={6} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Nom <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control
                                                    className="border border rounded-2"
                                                    onChange={(e) => setNom(e.target.value)}
                                                    value={nom}
                                                    placeholder=""
                                                    name="nom"
                                                    aria-label="nom"
                                                    maxLength={100}
                                                    minLength={2}
                                                    type="text"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Prénom (s) <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control
                                                    className="border border rounded-2"
                                                    onChange={(e) => setPrenoms(e.target.value)}
                                                    value={prenoms}
                                                    placeholder=""
                                                    name="prenoms"
                                                    aria-label="prenoms"
                                                    maxLength={100}
                                                    minLength={3}
                                                    type="text"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Email <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control
                                                    className="border border rounded-2"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                    placeholder=""
                                                    name="email"
                                                    aria-label="email"
                                                    maxLength={100}
                                                    minLength={3}
                                                    type="email"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Téléphone <i className="text-danger">*</i>{"  "}<i className="text-info bi bi-question-circle-fill" title="Veuillez choisir le drapeau et compléter votre numéro"></i>
                                                </Form.Label>
                                                <div
                                                ><PhoneInput
                                                        className="border  border rounded-2" style={{ height: "38px", backgroundColor: "#abbcd556" }}
                                                        defaultCountry="BJ"
                                                        value={telephone}
                                                        onChange={handleChange}
                                                        inputclass={`form-input`}
                                                        placeholder="61 00 00 00"
                                                        required
                                                    />

                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col md={4} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Numero arrêt </Form.Label>
                                                <Form.Control
                                                    className="border border rounded-2"
                                                    onChange={(e) => setNumArret(e.target.value)}
                                                    value={num_arret}
                                                    placeholder=""
                                                    name="num_arret"
                                                    aria-label="num_arret"
                                                    maxLength={100}
                                                    minLength={3}
                                                    required
                                                    type="text"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Date arrêt <i className="text-danger">*</i></Form.Label>
                                                <Form.Control
                                                    className="border border rounded-2"
                                                    onChange={(e) => setDateArret(e.target.value)}
                                                    value={date_arret}
                                                    placeholder=""
                                                    name="date_arret"
                                                    aria-label="date_arret"
                                                    required
                                                    type="date"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Réference dossier <i className="text-danger">*</i></Form.Label>
                                                <Form.Control
                                                    className="border border rounded-2"
                                                    onChange={(e) => setReferenceDossier(e.target.value)}
                                                    value={reference_dossier}
                                                    placeholder=""
                                                    name="reference_dossier"
                                                    aria-label="reference_dossier"
                                                    maxLength={100}
                                                    minLength={3}
                                                    type="text"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Nature affaire<i className="text-danger">*</i>
                                                </Form.Label>
                                                <textarea value={nature_affaire} name="nature_affaire" id="" cols="30" onChange={(e) => setNatureAffaire(e.target.value)} rows="3" className="form-control summernote border border rounded-2"
                                                    placeholder="Contentieux de paiement..."
                                                    maxLength={100}
                                                    minLength={3}></textarea>

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <div className="mb-3">
                                        <strong className="mb-4 me-3">Mode de paiements disponibles <i className="text-danger">*</i></strong>
                                    </div>
                                    <div className="row mb-4 pt-2 p-fluid">
                                        {modespaiements.length > 0 && modespaiements.map((item, index) => (
                                            <div className="col-md-4" key={index}>
                                                <div className="p-field">
                                                    <RadioButton className="me-2" inputId={`option-${index}`} required value={item.code} onChange={handleSetModePaiement} checked={mode_paiement_id === item.code} />
                                                    <label htmlFor={`option-${index}`} className="fw-bold">{item.libelle}</label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <Modal show={loading} backdrop="static" keyboard={false} centered>
                                        <Modal.Body className="text-center">
                                            <ProgressSpinner />
                                            <p className="my-3 fw-bold" style={{ fontSize: '18px' }}>Veuillez patienter pendant l'enregistrement de vos données...</p>
                                        </Modal.Body>
                                    </Modal>
                                    <Button type="submit" variant="warning me-3" onClick={handleSubmit} disabled={loading}>Enregistrer</Button> {/* Désactiver le bouton pendant le chargement */}
                                </Form>
                            </div>
                            {/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}
                    </div>
                    {/*//container-fluid*/}
                </div>
                {/*//app-content*/}
            </main>

        </LayoutActeur>
    );
}