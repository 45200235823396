import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery";
//import 'datatables.net-bs5/js/dataTables.bootstrap5.min.mjs'
//import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import PaiementsService from "../../../services/PaiementsService";
import {
  afficherErrorToast,
  afficherMessagesErreur,
  afficherMessagesSuccess,
  afficherOneMessageError,
  formatAmount,
  setDataTable,
} from "../../../utils/helpers";
import { Dialog } from "primereact/dialog";
import PhoneInput from "react-phone-number-input";
import { getButtonConfig } from "../../../utils/ButtonUtils";

export default function PaiementsIndex() {
  //##########################/// METHODES ##########################///
  const [statuts, setStatuts] = useState([]);
  const [pays, setPays] = useState([]);
  const [paiements, setPaiements] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [detailLabel, setDetailLabel] = useState("");
  const [statut_id, setStatut_id] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [titreModal, setTitreModal] = useState("");
  const [paiement_ref, setPaiementRef] = useState("");

  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const profils = useSelector((state) => state.auth.profil);

  //Data pour edit modal
  const [type, setType] = useState("");
  const [telephone, setTelephone] = useState("");
  const [date_transaction, setDateTransaction] = useState("");
  const [reference_transaction, setReferenceTransaction] = useState("");

  //get liste statuts
  const fetchStatuts = async () => {
    try {
      const response = await StatutsService.index(token);
      const liste_statuts = response.data.data;
      const filteredData = liste_statuts.filter(
        (option) =>
          option.libelle == "PAYE" ||
          option.libelle == "INTIE" ||
          option.libelle == "EN-ATTENTE" ||
          option.libelle == "INITIE"
      );

      setStatuts(filteredData);
    } catch (error) {
      console.error("Erreur lors de la récupération  :", error);
    }
  };

  const getStatuts = (statut_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Statut = statuts.find((item) => {
      return item.id == idToFind;
    });

    return Statut ? Statut.libelle : "N/A";
  };

  const [total_paiements, setTotalPaiements] = useState({});
  //get liste paiements
  const fetchPaiements = async () => {
    try {
      const response = await PaiementsService.index(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setPaiements(responseData.data);
        setTotalPaiements(responseData.total_paiements);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  const changeStatut = (ref, statut, reference_paiement) => {
    setDetailLabel(ref);
    setStatut_id(statut);
    setShowModal(true);
    setTitreModal(reference_paiement);
    setPaiementRef(ref);
  };

  const editPaiement = (ref, statut, reference_paiement, type, telephone) => {
    setDetailLabel(ref);
    setStatut_id(statut);
    setShowEditModal(true);
    setTitreModal(reference_paiement);
    setPaiementRef(ref);
    setType(type);
    setTelephone(telephone);
  };

  const handleChange = (value, country) => {
    setTelephone(value);
  };

  //Changer le statut
  const handleChangeStatut = async (e) => {
    e.preventDefault();
    let form = { ref: paiement_ref, statut_id };
    await PaiementsService.changeStatut(form)
      .then((response) => {
        if (response.status === 200) {
          setShowModal(false);
          afficherMessagesSuccess(response.data.message);
          fetchPaiements();
        }
      })
      .catch((error) => {
        console.log(error);
        afficherOneMessageError(error.response.data.message);
      });
  };

  const handleSaveEditModal = async (e) => {
    setShowEditModal(false);
    e.preventDefault();
    let form = {
      ref: paiement_ref,
      statut_id,
      date_paiement: date_transaction,
      reference_transaction,
      type,
      telephone,
    };

    await PaiementsService.generateQuittance(form, token)
      .then((response) => {
        if (response.status === 200) {
          setShowEditModal(false);
          afficherMessagesSuccess(response.data.message);
          fetchPaiements();
        }
      })
      .catch((error) => {
        console.log(error);
        afficherOneMessageError(error.response.data.message);
      });
  };

  const downloadQuittance = async (reference, type) => {
    const isEncrypt = false;
    //alert(reference)
    if (reference == undefined || reference == null) {
      afficherErrorToast("Impossible de générer la quittance");
      return;
    }
    //Check type de paiement effectué
    if (type === "demande_arret") {
      await PaiementsService.downloadDemandeQuittance(
        reference,
        isEncrypt,
        token
      )
        .then((response) => {
          if (response.status == 200) {
            // Créer un lien de téléchargement pour le fichier PDF
            const blob = new Blob([response.data], { type: "application/pdf" });
            const urlBlob = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = urlBlob;
            link.setAttribute("download", `quittance_${reference}.pdf`); // Nom du fichier téléchargé
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Nettoyer le DOM après le téléchargement

            afficherMessagesSuccess("Quittance téléchargée avec succès.");
          }
        })
        .catch((error) => {
          console.log(error);
          afficherMessagesErreur(error.response.data);
        });
    } else {
      await PaiementsService.downloadDossierQuittance(
        reference.replace(/\//g, "_"),
        isEncrypt,
        token
      )
        .then((response) => {
          if (response.status == 200) {
            // Créer un lien de téléchargement pour le fichier PDF
            const blob = new Blob([response.data], { type: "application/pdf" });
            const urlBlob = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = urlBlob;
            link.setAttribute("download", `quittance_${reference}.pdf`); // Nom du fichier téléchargé
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Nettoyer le DOM après le téléchargement

            afficherMessagesSuccess("Quittance téléchargée avec succès.");
          }
        })
        .catch((error) => {
          console.log(error);
          afficherMessagesErreur(error.response.data);
        });
    }
  };

  const sendQuittanceEmail = async (ref, type) => {
    let form = {
      ref,
      type,
    };
    await PaiementsService.sendQuittanceEmail(form, token)
      .then((response) => {
        if (response.status === 200) {
          afficherMessagesSuccess(response.data.message);
          fetchPaiements();
        }
      })
      .catch((error) => {
        console.log(error);
        afficherOneMessageError(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchPaiements();
    fetchStatuts();
    $("#submenu-4").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Liste des paiements{" "}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center">
              <div className="col-md-12">
                <h3 className="app-page-title mb-3">Liste des paiements</h3>
              </div>

              {/* Cartes pour les montants */}
              <div className="col-md-4">
                <div className="card border-primary shadow-sm paiement">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <i className="bi bi-cash-coin fs-3 text-primary me-3"></i>
                      <div>
                        <h5 className="card-title mb-0 text-primary">
                          Total Paiements
                        </h5>
                        <p className="card-text fs-4 fw-bold">
                          {total_paiements
                            ? formatAmount(total_paiements.total)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card border-success shadow-sm paiement">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <i className="bi bi-check-circle fs-3 text-success me-3"></i>
                      <div>
                        <h5 className="card-title mb-0 text-success">
                          Montant Payé
                        </h5>
                        <p className="card-text fs-4 fw-bold">
                          {total_paiements
                            ? formatAmount(total_paiements.montant_paye)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card border-danger shadow-sm paiement">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <i className="bi bi-x-circle fs-3 text-danger me-3"></i>
                      <div>
                        <h5 className="card-title mb-0 text-danger">
                          Montant Impayé
                        </h5>
                        <p className="card-text fs-4 fw-bold">
                          {total_paiements
                            ? formatAmount(total_paiements.montant_impaye)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Reference paiement</th>
                        <th className="">Objet</th>
                        <th className="">Montant</th>
                        <th className="">Réference transaction</th>
                        <th className="">Statut</th>
                        <th className="">Date</th>
                        <th className="">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {paiements.length > 0 &&
                        paiements.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell fw-bold">{item.reference}</td>
                            <td className="cell">
                              {item.demande_arret_id != null
                                ? item.get_demande_arret?.get_frais_paiement
                                    ?.description
                                : "Enrôlement"}
                            </td>
                            <td className="cell">
                              {formatAmount(item.montant)}
                            </td>
                            <td className="cell fw-bold">
                              {item.get_mode_paiement &&
                                item.get_mode_paiement.libelle}
                              <br />
                              {item.reference_transaction}
                            </td>
                            <td className="cell">
                              <span
                                className={
                                  getStatuts(item.statut_id) == "PAYE"
                                    ? "badge bg-success"
                                    : "badge bg-danger"
                                }
                              >
                                {getStatuts(item.statut_id)}
                              </span>
                            </td>
                            <td className="cell">
                              <span>
                                {dayjs(item.created_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>
                            </td>
                            <td className="cell">
                              <div className="d-flex">
                                {(profils.includes("SUPERADMIN") ||
                                  profils.includes("ADMIN")) && (
                                  <>
                                    <Button
                                      className={`btn btn-sm btn-${
                                        getButtonConfig("EDIT")?.color
                                      }`}
                                      onClick={() =>
                                        editPaiement(
                                          item.ref,
                                          item.statut_id,
                                          item.reference,
                                          item.demande_arret_id != null
                                            ? "demande_arret"
                                            : "dossier",
                                          item.telephone
                                        )
                                      }
                                      title="Editer le paiement"
                                    >
                                      <i
                                        className={`fa ${
                                          getButtonConfig("EDIT")?.icon
                                        }`}
                                      ></i>
                                    </Button>
                                    &nbsp;
                                    <Button
                                      className={`btn btn-sm btn-${
                                        getButtonConfig("EXPORT")?.color
                                      }`}
                                      title="Télécharger la quittance"
                                      onClick={() =>
                                        downloadQuittance(
                                          item.demande_arret_id != null
                                            ? item.get_demande_arret
                                                ?.reference_demande
                                            : item.get_dossier
                                                ?.reference_dossier,
                                          item.demande_arret_id != null
                                            ? "demande_arret"
                                            : "dossier"
                                        )
                                      }
                                    >
                                      <i className="bi bi-file-earmark-pdf"></i>
                                    </Button>
                                    &nbsp;
                                    <Button
                                      className={`btn btn-sm btn-${
                                        getButtonConfig("STATUT")?.color
                                      }`}
                                      title="Envoyer la quittance"
                                      onClick={() =>
                                        sendQuittanceEmail(
                                          item.ref,
                                          item.demande_arret_id != null
                                            ? "demande_arret"
                                            : "dossier"
                                        )
                                      }
                                    >
                                      <i className="bi bi-send-fill"></i>
                                    </Button>
                                  </>
                                )}
                                {/**getStatuts(item.statut_id) !== "PAYE" ? (
                                  <>
                                    <Button
                                      className="btn btn-sm btn-primary"
                                      onClick={() =>
                                        changeStatut(
                                          item.ref,
                                          item.statut_id,
                                          item.reference
                                        )
                                      }
                                      title="Changer le statut du paiement"
                                    >
                                      <i className="bi bi-arrow-repeat"></i>
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    {/**<Button className="btn btn-sm btn-danger" title="Télécharger la quittance">
                                                                    <i className="bi bi-file-pdf"></i>
                                                                </Button> 
                                  </>
                                )**/}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}

                {/* Modal change statut*/}
                <Dialog
                  header={titreModal}
                  visible={showModal}
                  onHide={handleCloseModal}
                  style={{
                    width: "700px",
                    borderBottom: "1px solid grey",
                    color: "GrayText",
                  }}
                  headerStyle={{ background: "#f0f0f0" }}
                >
                  <div className="p-fluid">
                    <div className="row">
                      <hr />
                      <p className="text-center fw-bold">
                        Changer le statut de la demande{" "}
                      </p>
                    </div>
                  </div>
                  <Form onSubmit={handleChangeStatut}>
                    <div className="row">
                      <Col md={12} sm={12} className="formx-groupx">
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">
                            Statut <i className="text-danger">*</i>
                          </Form.Label>
                          <Form.Select
                            className=""
                            id="statut_id"
                            name="statut_id"
                            value={statut_id}
                            required
                            onChange={(e) => {
                              setStatut_id(e.target.value);
                            }}
                          >
                            <option value="" selected>
                              -- Sélectionnez --
                            </option>
                            {statuts.length > 0 &&
                              statuts.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.libelle}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </div>

                    <div className="p-dialog-footerx mt-4">
                      {/* Pied de page du modal */}
                      <Button type="submit" variant="warning me-3">
                        Enregistrer
                      </Button>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        Annuler
                      </Button>
                    </div>
                  </Form>
                </Dialog>

                {/* Modal */}
                <Dialog
                  header={titreModal}
                  visible={showEditModal}
                  onHide={handleCloseEditModal}
                  style={{
                    width: "700px",
                    borderBottom: "1px solid grey",
                    color: "GrayText",
                  }}
                  headerStyle={{ background: "#f0f0f0" }}
                >
                  <div className="p-fluid">
                    <div className="row">
                      <hr />
                      <p className="text-center fw-bold">
                        Modifier le paiement
                      </p>
                    </div>
                  </div>
                  <Form onSubmit={handleSaveEditModal}>
                    <div className="row">
                      <Col md={12} sm={12} className="formx-groupx">
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">
                            Statut <i className="text-danger">*</i>
                          </Form.Label>
                          <Form.Select
                            className=""
                            id="statut_id"
                            name="statut_id"
                            value={statut_id}
                            required
                            onChange={(e) => {
                              setStatut_id(e.target.value);
                            }}
                          >
                            <option value="" selected>
                              -- Sélectionnez --
                            </option>
                            {statuts.length > 0 &&
                              statuts.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.libelle}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={12} sm={12} className="form-group">
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">
                            Téléphone <i className="text-danger"></i>&nbsp;
                            <i
                              className="text-info bi bi-question-circle-fill"
                              title="Veuillez choisir le drapeau et compléter votre numéro"
                            ></i>
                          </Form.Label>
                          <div>
                            <PhoneInput
                              className="border rounded-2"
                              style={{
                                height: "38px",
                                backgroundColor: "#abbcd556",
                              }}
                              defaultCountry="BJ"
                              value={telephone}
                              onChange={handleChange}
                              inputclass={`form-input`}
                              placeholder="60 00 00 00"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={12} sm={12} className="formx-groupx">
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">
                            Date de paiement <i className="text-danger"></i>
                          </Form.Label>
                          <Form.Control
                            className="border rounded-2"
                            onChange={(e) => setDateTransaction(e.target.value)}
                            value={date_transaction}
                            placeholder=""
                            name="date_transaction"
                            aria-label="date_transaction"
                            type="datetime-local"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} sm={12} className="formx-groupx">
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">
                            Référence transaction{" "}
                            <i className="text-danger"></i>
                          </Form.Label>
                          <Form.Control
                            className="border rounded-2"
                            onChange={(e) =>
                              setReferenceTransaction(e.target.value)
                            }
                            value={reference_transaction}
                            placeholder=""
                            name="reference_transaction"
                            aria-label="reference_transaction"
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                    </div>

                    <div className="p-dialog-footerx mt-4">
                      {/* Pied de page du modal */}
                      <Button type="submit" variant="warning me-3">
                        Enregistrer
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleCloseEditModal}
                      >
                        Annuler
                      </Button>
                    </div>
                  </Form>
                </Dialog>
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
