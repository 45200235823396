import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import Swal from "sweetalert2";
import {
  checkAuthenticate,
  resetAuthData,
} from "../../../app/providers/authSlice";
import { logoutRequest } from "../../auth/logout";

export default function Header() {
  const navigate = useNavigate();
  const profils = useSelector((state) => state.auth.profil);

  const handleToggleSidebar = () => {
    document.body.classList.toggle("toggle-sidebar");
  };
  const user = useSelector((state) => state.auth.user);
  //  console.log(user ? user.nom : 'Utilisateur non connecté')

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // se déconnecter
  const handleLogout = () => {
    Swal.fire({
      icon: "question",
      text: "Voulez-vous vraiment vous déconnecter ? Cela terminera votre session actuelle.",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui ",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        logoutRequest(auth.token);
        dispatch(resetAuthData());
        navigate(myroutes.login);
        Swal.fire({
          icon: "success",
          text: "Vous vous êtes déconnecté avec succès",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          background: "green",
          color: "white",
          iconColor: "white",
        });
      }
    });
  };

  //Redirection vers la page mon profil
  const goToProfil = () => {
    navigate(`${myroutes.utilisateurs_show}/${user.ref}`);
  };

  // check if user is authenticate
  dispatch(checkAuthenticate());
  // Vérifier si le token existe et n'est pas expiré
  if (!auth.isAuthenticate) {
    //deconnecter l'utilisateur et renvoi sur login page
    dispatch(resetAuthData());
    navigate(myroutes.login);
  }

  useEffect(() => {}, [navigate]);

  return (
    <React.Fragment>
      {/** ======= Header ======= */}
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          backgroundColor: "#3B7D82",
          width: "300px",
          height: "60px",
          marginLeft: "-20px",
        }}
      >
        <Link
          to={myroutes.login}
          className="logo d-flex align-items-center"
          style={{ marginLeft: "20px" }}
        >
          <img
            src="/NiceAdmin/assets/img/logo-cacc_10140x1140px_blanc.png"
            alt="logo-cacc_10140x1140px_blanc"
          />
          <span className="d-none d-lg-block" style={{ color: "#fff" }}>
            COUR D'APPEL DE COMMERCE DE COTONOU
          </span>
        </Link>
      </div>
      <button className="toggle-sidebar-btn" onClick={handleToggleSidebar}>
        <i className="bi bi-list"></i>
      </button>
      {/** End Logo */}

      <div className="search-bar">
        <h4
          className="fw-bold text-warning"
          style={{
            fontWeight: "lighter",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          Plateforme d'Enrôlement et de Gestion des Procédures
        </h4>
      </div>
      {/** End Search Bar */}

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li
            className="nav-item dropdown pe-5"
            style={{ paddingLeftx: "100px" }}
          >
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <img
                src="/NiceAdmin/assets/img/favicon-logo.png"
                alt="Profile"
                className="rounded-circle"
              />
              <span
                className="d-none d-md-block dropdown-toggle ps-2"
                style={{ fontFamily: "montserrat" }}
              >
                {user ? user.nom : "Utilisateur non connecté"}{" "}
                {user ? user.prenoms : "Utilisateur non connecté"}
              </span>
            </a>
            {/** End Profile Iamge Icon */}

            <ul
              className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"
              style={{ fontFamily: "montserrat" }}
            >
              <li className="dropdown-header">
                <h6> {user ? user.email : "Utilisateur non connecté"}</h6>
                <span>
                  {" "}
                  {user ? user.telephone : "Utilisateur non connecté"}
                </span>
              </li>
              {profils.includes("SUPERADMIN") && (
                <>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="#"
                      onClick={() => goToProfil()}
                    >
                      <i className="bi bi-person"></i>
                      <span>Mon Profil</span>
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to={myroutes.voirtout}
                    >
                      <i className="bi bi-gear"></i>
                      <span>Paramètres</span>
                    </Link>
                  </li>
                </>
              )}

              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <i className="bi bi-question-circle"></i>
                  <span>Besoin d'aide ?</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <Link                  
                  className="dropdown-item d-flex align-items-center"
                  to={myroutes.change_password}
                >
                  <i className="bi bi-p-square"></i>
                  <span>Changer le mot de passe</span>
                </Link>
              </li>

              <li>
                <a
                  onClick={() => handleLogout()}
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Se déconnecter</span>
                </a>
              </li>
            </ul>
            {/** End Profile Dropdown Items */}
          </li>
          {/** End Profile Nav */}
        </ul>
      </nav>
      {/** End Icons Navigation */}
      {/** End Header */}
    </React.Fragment>
  );
}
