import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../../backoffice/admin/include/layout";
import $ from "jquery";
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import ProfilsService from "../../../../services/ProfilsService";
import { copyToClipboard, setDataTable } from "../../../../utils/helpers";
import { getButtonConfig } from "../../../../utils/ButtonUtils";

export default function ProfilsIndex() {
  //##########################/// METHODES ##########################///
  const [profils, setProfils] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  //get liste profils
  const fetchProfils = async () => {
    await ProfilsService.index(token)
      .then((response) => {
        setProfils(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    //Afficher dataTable
    setDataTable();
  };

  //redirection sur la page edit sans afficher les id dans l'url
  const goToEdit = (param) => {
    navigate(myroutes.profils_edit, { state: { ref: param } });
  };

  const handleCopyReference = (reference, itemId) => {
    copyToClipboard(reference);
  };
  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        ProfilsService.delete(param, token)
          .then((response) => {
            if (response.status === 200) {
              //console.log(response.status);
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              window.location.reload(true);

              //fetchProfils()
            } else {
              Swal.fire({
                icon: "error",
                text: response.data.message,
              });
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.response.data.message,
            });
            console.log(e);
          });
      }
    });
  };

  useEffect(() => {
    fetchProfils();
    $("#parametres-nav").addClass("show");
  }, []);

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">Profils </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  Liste des profils{" "}
                  <Link
                    className={`btn btn-sm btn-${
                      getButtonConfig("CREATE")?.color
                    }`}
                    to={myroutes.profils_create}
                    title="Ajouter"
                  >
                    <i className={`fa ${getButtonConfig("CREATE")?.icon}`}></i>
                    &nbsp;Ajouter
                  </Link>{" "}
                </h3>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="text-center">N°</th>
                        <th className="text-center">Libelle</th>
                        <th className="text-center">Code</th>
                        <th className="text-center">Habilitation</th>
                        <th className="text-center">Statut</th>
                        <th className="text-center">Date modification</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {profils.length > 0 &&
                        profils.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.libelle}</td>
                            <td className="text-center">{item.code}</td>
                            <td className="text-center">
                              {item.get_profil_habilitation.map(
                                (option, optionIndex) => (
                                  <span
                                    key={optionIndex}
                                    className="badge text-bg-success me-2 fs-6 h1"
                                  >
                                    {option.get_habilitation
                                      ? option.get_habilitation.libelle
                                      : "---"}
                                  </span>
                                )
                              )}
                            </td>
                            <td className="text-center">
                              {item.get_statut.libelle}
                            </td>
                            <td className="text-center">
                              <span>
                                {dayjs(item.updated_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>
                            </td>
                            <td className="text-center d-flex">
                              <Button
                                className={`btn btn-sm btn-${
                                  getButtonConfig("EDIT")?.color
                                }`}
                                onClick={() => goToEdit(item.ref)}
                                title="Modifier"
                              >
                                <i
                                  className={`fa ${
                                    getButtonConfig("EDIT")?.icon
                                  }`}
                                ></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className={`btn btn-sm btn-${
                                  getButtonConfig("DELETE")?.color
                                }`}
                                onClick={() => onDelete(item.ref)}
                                title="Supprimer"
                              >
                                <i
                                  className={`fa ${
                                    getButtonConfig("DELETE")?.icon
                                  }`}
                                ></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className="btn btn-sm btn-success"
                                onClick={() =>
                                  handleCopyReference(item.libelle, item.id)
                                }
                              >
                                <i className="bi bi-clipboard2-fill"></i>Ref
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
