import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../styles/styles.css";
import { myroutes } from "../routes/webroute";
import { Button, Modal } from "react-bootstrap";
import { ProgressSpinner } from "primereact/progressspinner";
import ModesPaiementsService from "../services/ModesPaiementsService";
import PaiementsService from "../services/PaiementsService";

import {
  afficherOneMessageError,
  formatAmount,
  getPaiementConfig,
} from "../utils/helpers";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { RadioButton } from "primereact/radiobutton";
import ButtonWithSpinner from "../components/ButtonWithSpinner";
import $ from "jquery";
import FraisPaiementsService from "../services/FraisPaiementsService";
import { useMediaQuery } from "@mui/material";

export default function PaiementDossier() {
  ///############### METHODES #############################//
  const [loading, setLoading] = useState(false);
  const [modespaiements, setModesPaiements] = useState([]);
  const [mode_paiement_id, setModePaiement_Id] = useState("");
  const [montant_payer, setMontantPayer] = useState(0);
  const [reference_dossier, setReferenceDossier] = useState("");
  const token = useSelector((state) => state.auth.token);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isBrowser = useMediaQuery("(min-width:600px)");

  //get liste mode de paiement
  const fetchModepaiements = async () => {
    await ModesPaiementsService.indexFrontoffice(token)
      .then((response) => {
        //Récupérer uniquement les modes de paiements actif
        setModesPaiements(
          response.data.data.filter((mode) => mode.statut_id === 1)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSetModePaiement = (e) => {
    setModePaiement_Id(e.target.value);
  };

  const storePaiement = () => {
    if (mode_paiement_id == "" || isDisabled) {
      return false;
    }

    //Afficher le hook de paiement
    //Cle kiosque digital
    var config = getPaiementConfig();
    openKkiapayWidget({
      amount: montant_payer,
      position: "center",
      data: { reference_dossier, mode_paiement_id },
      //name: auth.user.fullname,
      theme: "green",
      api_key: config.key,
      sandbox: config.isSandbox,
      //email: auth.user.email,
      //phone: parseInt(auth.user.telephone)
    });
  };

  //Get kkiapay reponse after paiement success
  const successHandler = async (response) => {
    setLoading(true); // Début du chargement

    //Recuperation du code de la transaction de kkiapay
    var code_transaction = response.transactionId;
    // Enregistrer le paiement
    let paiementsInfo = {
      montant: montant_payer,
      reference_dossier: response.data.reference_dossier,
      modepaiement_id: response.data.mode_paiement_id,
    };
    await PaiementsService.store(code_transaction, paiementsInfo, token)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          //Redirection sur la page de login
          navigate(myroutes.login);
          Swal.fire({
            icon: "success",
            title: "Enregistrement réussi",
            text: res.data.data.transaction.message,
          });

          // Actualiser la page
          /*setTimeout(() => {
                    window.location.reload();
                }, 1000);*/
        } else if (res.data.data.echec) {
          setLoading(false); // Fin du chargement en cas d'erreur de validation

          Swal.fire({
            icon: "warning",
            title: "Une erreur s'est produite !",
            text: res.data.data.echec,
          });
        } else if (res.data.erreur) {
          setLoading(false); // Fin du chargement en cas d'erreur de validation

          Swal.fire({
            icon: "error",
            title: "Erreur!",
            text: res.data.message,
          });
        }
      })
      .catch((error) => {
        setLoading(false); // Fin du chargement en cas d'erreur de validation
        console.log("Error", error);
      });
  };

  const handleBack = () => {
    navigate(myroutes.login);
  };

  useEffect(() => {
    //checkPaiement();
    fetchModepaiements();

    const checkPaiement = async () => {
      const url = location.pathname; // Utilisation de location.pathname pour obtenir l'URL sans le nom de domaine
      const referenceDossier = url.split("paiement/")[1]; // Obtenez la partie de l'URL après "paiement/"
      if (referenceDossier) {
        setLoading(true);
        try {
          //console.log(referenceDossier);

          const response = await PaiementsService.checkPaiement(
            { reference_dossier: referenceDossier },
            token
          );
          if (response.status === 200) {
            //console.log(response.data.data);
            setModePaiement_Id(response.data.data.mode_paiement_id);
            setReferenceDossier(response.data.data.reference_dossier);
            setMontantPayer(response.data.data.montant);
            setLoading(false);
            $("#button-payer").css("display", "");
          }
        } catch (error) {
          //Swal.close();
          $("#button-payer").css("display", "none");

          //console.log(error);
          setLoading(false);
          setIsDisabled(true);

          afficherOneMessageError(error.response.data.message);
        }
      }
    };

    checkPaiement(); // Appel de la fonction au chargement de la page

    addKkiapayListener("success", successHandler);
    return () => {
      removeKkiapayListener("success", successHandler);
    };
  }, []);
  ///############### END METHODES #############################//

  return (
    <React.Fragment>
      <main
        style={{
          backgroundImage: "url('../NiceAdmin/assets/img/log3.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
         <section className={`mb-3 ${isMobile ? "header-paiement" : "header-login" }`}>
          <div className="container p-1 pt-3">
            <div className="row align-items-center">
              {/* Logo Section */}
              <div
                className={`col-${isMobile ? "12" : "2"} text-${
                  isMobile ? "center" : "start"
                }`}
              >
                <Link to={myroutes.accueil}>
                  <img
                    style={{
                      height: "100px",
                      width: isMobile ? "auto" : "120px",
                    }}
                    src="/NiceAdmin/assets/img/logo-blanc.png"
                    alt="logo-blanc"
                  />
                </Link>
              </div>

              {/* Spacer */}
              {!isMobile && <div className="col-1"></div>}

              <div className={`col-${isMobile ? "12" : "6"} text-center`}>
                <h3
                  className="fw-bold"
                  style={{
                    fontSize: isMobile ? "20px" : "24px",
                  }}
                >
                  COUR D'APPEL DE COMMERCE - COTONOU
                </h3>
                <hr className="my-2" />
                <p
                  className="fw-bold text-warning"
                  style={{
                    fontWeight: "lighter",
                    fontSize: isMobile ? "16px" : "21px",
                  }}
                >
                  Plateforme d'Enrôlement et de Gestion des Procédures
                </p>
              </div>

              {/* Spacer */}
              {!isMobile && <div className="col-1"></div>}

              {/* Ministry Logo */}
              {!isMobile && (
                <div className="col-2 text-end">
                  <img
                    style={{
                      height: "70px",
                      width: "230px",
                      paddingTop: "20px",
                    }}
                    src="/NiceAdmin/assets/img/ministere.png"
                    alt="ministere-logo"
                  />
                </div>
              )}
            </div>
          </div>
        </section>

        <section className={`${isMobile ? "mt-5" : "mt-3"}`}>
          <div className="container mt-2">
            <div className="row justify-content-center">
            <div
                className={`col-${
                  isMobile ? "12" : "lg-4"
                } col-md-6 d-flex flex-column align-items-center justify-content-center`}
              >
                <div className="container mt-0"></div>
                <div
                  className="card mb-3 mt-3"
                  style={{
                    width: isMobile ? "100%" : "550px",
                  }}
                >
                  <div className="card-body">
                    <div className="pt-3 pb-2">
                      <div className="pt-0 pb-2 text-center mb-3">
                        <h5
                          className="card-title text-center pb-0 fs-4"
                          style={{ color: "#3B7D82" }}
                        >
                          Espace de paiement
                        </h5>
                      </div>

                      <div className="auth-formxx">
                        <div className="row mb-4 pt-2 p-fluid">
                        <div className={`col-${isMobile ? "12" : "md-6"}`}>
                            <h5 className="mb-2 me-3">
                              Montant à payer <i className="text-danger">*</i> :
                            </h5>
                            <h4 className="text-success">
                              {formatAmount(montant_payer)}
                            </h4>
                          </div>
                          <div
                            className={`col-${isMobile ? "12" : "md-6"} text-${
                              isMobile ? "center" : "end"
                            }`}
                          >
                            <img
                              src="/paiement-kkiapay.png"
                              alt="modepaiement"
                              className="img-fluid  me-2 p-1"
                              style={{
                                height: "auto",
                                width: isMobile ? "100%" : "300px",
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 mb-2">
                            <ButtonWithSpinner
                              loading={loading}
                              type="submit"
                              id="button-payer"
                              onClick={storePaiement}
                              style={{
                                color: "#000",
                                fontSize: "18px",
                              }}
                              className="btn app-btn-dark bg-warning text-black w-100 fw-bold"
                            >
                              Payer
                            </ButtonWithSpinner>
                          </div>
                          <div className="col-md-6">
                            <ButtonWithSpinner
                              type="button"
                              onClick={handleBack}
                              style={{
                                fontSize: "18px",
                              }}
                              className="btn app-btn-dark bg-secondary text-white w-100 fw-bold"
                            >
                              Annuler
                            </ButtonWithSpinner>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </section>
        <section className="footer-login">
        <div className="row w-100 m-0">
            <div
              className={`col-${isMobile ? "4" : "md-4"}`}
              style={{ height: "8px", backgroundColor: "#008559" }}
            ></div>
            <div
              className={`col-${isMobile ? "4" : "md-4"}`}
              style={{ height: "8px", backgroundColor: "#FFD400" }}
            ></div>
            <div
              className={`col-${isMobile ? "4" : "md-4"}`}
              style={{ height: "8px", backgroundColor: "#E43009" }}
            ></div>
          </div>
          <div className="container text-center py-2">
            <small className="copyright">
              &copy;{new Date().getFullYear()} COUR D'APPEL DE COMMERCE -
              COTONOU | Tous droits réservés.
              {/*
              Conception & Réalisation :{" "}
              <a className="app-link" href="www.digiweb.bj" target="_blank">
                DigiWeb SARL
              </a>
              */}
            </small>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
