import http from "./http";

class PaiementsService {
    index(token){
        return http.get("/api/v01/web/paiements/allPaiements",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    store(code_transaction, data, token){
        return http.post(`/api/v01/web/paiement_store/${code_transaction}`, data, { headers: { 'Authorization': 'Bearer '+token } })
    } 

    checkPaiement(data, token){
        return http.post("/api/v01/web/check/paiement", data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    checkPaiementDemande(data, token){
        return http.post("/api/v01/web/check/paiement/demandes-arrets", data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    changeStatut(data, token){
        return http.post("/api/v01/web/paiements/update_statut", data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    generateQuittance(data, token){
        return http.post(`/api/v01/web/sendQuittance`,data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    demandeStore(code_transaction, data, token){
        return http.post(`/api/v01/web/demandearret_paiement_store/${code_transaction}`, data, { headers: { 'Authorization': 'Bearer '+token } })
    } 

    downloadDossierQuittance(reference, is_encrypt, token){
        return http.get(`/api/v01/web/quittance/dossier/${reference}/${is_encrypt}`, { headers: { 'Authorization': 'Bearer '+token }, responseType: "blob" })
    }

    downloadDemandeQuittance(reference, is_encrypt, token){
        return http.get(`/api/v01/web/quittance/demande/${reference}/${is_encrypt}`, { headers: { 'Authorization': 'Bearer '+token }, responseType: "blob" })
    }

    sendQuittanceEmail(data, token){
        return http.post(`/api/v01/web/send-quittance`, data, { headers: { 'Authorization': 'Bearer '+token } })
    }

}

export default new PaiementsService()