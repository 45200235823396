import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Layout from "../include/layout";
import PaysService from "../../../services/PaysService";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Importez les styles par défaut de la bibliothèque
import UtilisateursService from "../../../services/UtilisateursService";
import StatutsService from "../../../services/StatutsService";
import CivilitesService from "../../../services/CivilitesService";
import ProfilsService from "../../../services/ProfilsService";
import { MultiSelect } from "primereact/multiselect";
import { useSelector } from "react-redux";
import { Editor } from "primereact/editor";
import { afficherMessagesErreur, afficherMessagesSuccess, filterStatut } from "../../../utils/helpers";
import { FiEye, FiEyeOff } from "react-icons/fi";

export default function UtilisateursEdit() {
  //Déclaration des champs du formulaire

  const [nom, setNom] = useState("");
  const [prenoms, setPrenoms] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [adresse, setAdresse] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmshowPassword, setShowconfirmPassword] = useState(false);
  const [pays, setPays] = useState([]);
  const [statuts, setStatuts] = useState([]);
  const [profils, setProfils] = useState([]);
  const [selectedOptionProfils, setSelectedOptionProfils] = useState([]);
  const [paysId, setpaysId] = useState("");
  const [selectedOptionPays, setSelectedOptionPays] = useState(null);
  const [validationError, setValidationError] = useState({});
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const token = useSelector((state) => state.auth.token);
  const [telephoneError, setTelephoneError] = useState("");
  //Creation des ascesseurs getters et setters pour tout le formulaire
  const [rccm, setRccm] = useState("");
  const [ifu, setIfu] = useState("");
  const [civilites, setCivilites] = useState([]);
  const [civilite_id, setCivilite_id] = useState("");
  const [pays_id, setPays_id] = useState("");
  const [statut_id, setStatut_id] = useState("");
  const location = useLocation();

  const [email_pro, setEmailPro] = useState("");
  const [email_sec, setEmailSec] = useState("");

  const [telephone_primaire, setTelephone_primaire] = useState("");
  const [telephone_secondaire, setTelephone_secondaire] = useState("");
  const [verify, setVerify] = useState(0);

  const navigate = useNavigate();

  const handleChange = (value, country) => {
    setTelephone(value);
    setTelephoneError("");
  };

  const handleChangeTelPrimaire = (value, country) => {
    setTelephone_primaire(value);
  };

  const handleChangeTelSecondaire = (value, country) => {
    setTelephone_secondaire(value);
  };

  //get liste pays
  const fetchPays = async () => {
    await PaysService.index(token)
      .then((response) => {
        setPays(response.data.data);
        //console.log("ok", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste civilites
  const fetchCivilites = async () => {
    await CivilitesService.index(token)
      .then((response) => {
        setCivilites(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // formater les données du pays

  const datapays = pays.map((item) => {
    return {
      label: item.nom_pays,
      value: item.id,
    };
  });
  //console.log("datapays", datapays)

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (confirmPassword && newPassword !== confirmPassword) {
      setPasswordError("Les mots de passe ne correspondent pas");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (newConfirmPassword !== password) {
      setPasswordError("Les mots de passe ne correspondent pas");
    } else {
      setPasswordError(""); //
    }
  };

  const getPasswordStrength = () => {
    const passwordToCheck = password;

    // Your regex for checking uppercase, lowercase, digit, and special characters
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[@/./?/#/!]/;

    let strength = "";

    // Ajoutez une condition pour vérifier que le mot de passe a au moins 8 caractères
    if (passwordToCheck.length < 8) {
      strength += "au moins 8 caractères, ";
    }

    if (!uppercaseRegex.test(passwordToCheck)) {
      strength += "une lettre majuscule, ";
    }

    if (!lowercaseRegex.test(passwordToCheck)) {
      strength += "une lettre minuscule, ";
    }

    if (!digitRegex.test(passwordToCheck)) {
      strength += "un chiffre, ";
    }

    if (!specialCharRegex.test(passwordToCheck)) {
      strength += "un caractère spécial (@, /, ., ?, #), ";
    }

    strength = strength.replace(/,\s*$/, "");

    if (strength === "") {
      return (
        <span style={{ color: "green", fontWeight: "bold" }}>
          Mot de passe fort
        </span>
      );
    } else {
      return (
        <span style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}>
          Le mot de passe doit contenir au moins {strength}
        </span>
      );
    }
  };

  /// Récupérez les données des statuts

  //get liste statuts
  const fetchStatuts = async () => {
    await StatutsService.index(token)
      .then((res) => {
        //const filteredData = res.data.data.filter((option) => (option.libelle == "ACTIF" || option.libelle == "INACTIF" || option.libelle == "BROUILLON"))
        setStatuts(filterStatut(res.data.data));
        // console.log("res.data.data", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste profils
  const fetchProfils = async () => {
    await ProfilsService.index(token)
      .then((res) => {
        setProfils(res.data.data);
        // console.log("res.data.data", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dataprofils = profils.map((item) => {
    return {
      label: item.libelle,
      value: item.id,
    };
  });

  const handleSelectedProfilsChange = (selectedOptionProfils) => {
    setSelectedOptionProfils(selectedOptionProfils);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    const strength = getPasswordStrength();
    setPasswordStrength(strength);

    const formData = {
      nom,
      prenoms,
      email,
      telephone,
      adresse,
      pays_id,
      rccm,
      ifu,
      civilite_id,
      statut_id,
      verify,
      password,
      password_confirmation: confirmPassword,
      profil_id: selectedOptionProfils, // Garde les profils comme un tableau
      email_sec,
      email_pro,
      telephone_primaire,
      telephone_secondaire,
    };

    // console.log(selectedOptionProfils);

    try {
      const response = await UtilisateursService.update(
        location.state.ref,
        formData,
        token
      );
      if (response.status === 200) {
        afficherMessagesSuccess(response.data.message)
        navigate(myroutes.utilisateurs_index);
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.erreur,
          showConfirmButton: true,
          timer: 3000,
        });
      }
    } catch (error) {
      afficherMessagesErreur(error.response?.data?.error)
      console.error(error);
    }
  };

  const edit = async () => {
    try {
      const response = await UtilisateursService.edit(
        location.state.ref,
        token
      );
      if (response.data) {
        const data = response.data.data;
        const profils = response.data.profils;
        //alert("ok")
        // console.log("profils", profils)
        // console.log("data", data)
        setEmail(data.email);
        setTelephone(data.telephone);
        setAdresse(data.adresse);
        setRccm(data.rccm);
        setIfu(data.ifu);
        setCivilite_id(data.civilite_id);
        setSelectedOptionProfils(profils.map((profil) => profil.id));
        setNom(data.nom);
        setPrenoms(data.prenoms);
        setStatut_id(data.statut_id);
        setVerify(data.verify);
        setPays_id(data.pays_id);
        setEmailPro(data.email_pro);
        setEmailSec(data.email_sec);
        setTelephone_primaire(data.telephone_primaire);
        setTelephone_secondaire(data.telephone_secondaire);
        
      } else {
        console.log("Données non trouvées dans la réponse de l'API");
      }
    } catch (error) {
      console.error("Erreur de l'API", error);
    }
  };

  useEffect(() => {
    // Vérifier si les identifiants sont sauvegardés dans le stockage local
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");

    // Remplir automatiquement les champs du formulaire s'ils existent
    if (savedEmail) {
      setEmail(savedEmail);
    }

    if (savedPassword) {
      setPassword(savedPassword);
    }
    fetchPays();
    fetchStatuts();
    fetchProfils();
    fetchCivilites();
    edit();
  }, []);

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">Utilisateurs </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h1 className="app-page-title mb-0">Enregistrement </h1>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-settings shadow-lg mb-5">
              <div className="app-card-body py-3">
                <Row>
                  {Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">{<li>{validationError}</li>}</ul>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
                <Form
                  className="mx-3 settings-form"
                  style={{ textAlign: "left" }}
                  onSubmit={handleUpdate}
                >
                  <Row>
                    <Col md={2} sm={2} className="formx-groupx">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Civilité <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Select
                          className=""
                          id="civilite_id"
                          name="civilite_id"
                          value={civilite_id}
                          // required
                          onChange={(e) => {
                            setCivilite_id(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            --
                          </option>
                          {civilites.length > 0 &&
                            civilites.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.libelle}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={5} sm={5} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Nom <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={(e) => setNom(e.target.value)}
                          value={nom}
                          placeholder=""
                          name="nom"
                          aria-label="nom"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={5} sm={5} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Prénom (s) <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={(e) => setPrenoms(e.target.value)}
                          value={prenoms}
                          placeholder=""
                          name="prenoms"
                          aria-label="prenoms"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={4} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Email <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          placeholder=""
                          name="email"
                          aria-label="email"
                          maxLength={100}
                          minLength={3}
                          type="email"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4} sm={4} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Email-pro <i className="text-danger"></i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={(e) => setEmailPro(e.target.value)}
                          value={email_pro}
                          placeholder=""
                          name="email_pro"
                          aria-label="email_pro"
                          maxLength={100}
                          minLength={3}
                          type="email"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4} sm={4} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Email-secondaire <i className="text-danger"></i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={(e) => setEmailSec(e.target.value)}
                          value={email_sec}
                          placeholder=""
                          name="email_sec"
                          aria-label="email_sec"
                          maxLength={100}
                          minLength={3}
                          type="email"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4} sm={4} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Téléphone <i className="text-danger"></i>
                        </Form.Label>
                        <PhoneInput
                          defaultCountry="BJ"
                          value={telephone}
                          onChange={handleChange}
                          inputClass={`form-input ${
                            telephoneError ? "is-invalid" : ""
                          }`}
                          containerStyle={{
                            border: "1px solid #ccc",
                            borderRadius: "6px",
                          }}
                          inputStyle={{
                            width: "80%",
                            border: "none",
                            outline: "none",
                          }}
                          placeholder="+229 61 00 00 00"
                        />
                        {telephoneError && (
                          <span className="invalid-feedback" role="alert">
                            <strong>{telephoneError}</strong>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={4} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Téléphone primaire<i className="text-danger"></i>{" "}
                          <i
                            className="text-info bi bi-question-circle-fill"
                            title="Veuillez choisir le drapeau et compléter votre numéro"
                          ></i>
                        </Form.Label>
                        <PhoneInput
                          className="border  border rounded-2"
                          style={{
                            height: "38px",
                            backgroundColor: "#abbcd556",
                          }}
                          defaultCountry="BJ"
                          value={telephone_primaire}
                          onChange={handleChangeTelPrimaire}
                          inputClass="form-input"
                          placeholder="+229 61 00 00 00"
                          name="telephone_primaire"
                          //inputStyle={{ width: '80%', border: '1px solid #ccc', borderRadius: '6px', padding: '8px', height: '30px' }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={4} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Téléphone secondaire<i className="text-danger"></i>{" "}
                          <i
                            className="text-info bi bi-question-circle-fill"
                            title="Veuillez choisir le drapeau et compléter votre numéro"
                          ></i>
                        </Form.Label>
                        <PhoneInput
                          className="border  border rounded-2"
                          style={{
                            height: "38px",
                            backgroundColor: "#abbcd556",
                          }}
                          defaultCountry="BJ"
                          value={telephone_secondaire}
                          onChange={handleChangeTelSecondaire}
                          inputClass="form-input"
                          placeholder="+229 61 00 00 00"
                          name="telephone_secondaire"
                          //inputStyle={{ width: '80%', border: '1px solid #ccc', borderRadius: '6px', padding: '8px', height: '30px' }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={4} className="formx-groupx">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Pays <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Select
                          className=""
                          id="pays_id"
                          name="pays_id"
                          value={pays_id}
                          // required
                          onChange={(e) => {
                            setPays_id(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            --
                          </option>
                          {pays.length > 0 &&
                            pays.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.nom_pays}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={4} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">RCCM</Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={(e) => setRccm(e.target.value)}
                          value={rccm}
                          placeholder=""
                          name="rccm"
                          aria-label="rccm"
                          maxLength={100}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={4} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">IFU</Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={(e) => setIfu(e.target.value)}
                          value={ifu}
                          placeholder=""
                          name="ifu"
                          aria-label="ifu"
                          maxLength={100}
                          type="number"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Adresse<i className="text-danger"></i>
                        </Form.Label>
                        <Editor
                          value={adresse}
                          name="adresse"
                          onTextChange={(e) => setAdresse(e.htmlValue)}
                          style={{ height: "100px" }}
                          placeholder="Boîte postale, localisation, ville, ..."
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4} sm={4} className="formx-groupx">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Statut <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Select
                          className=""
                          id="statut_id"
                          name="statut_id"
                          value={statut_id}
                          // required
                          onChange={(e) => {
                            setStatut_id(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            --
                          </option>
                          {statuts.length > 0 &&
                            statuts.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.libelle}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={4} className="formx-groupx">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Email vérifié <i className="text-danger"></i>
                        </Form.Label>
                        <Form.Select
                          className=""
                          id="verify"
                          name="verify"
                          value={verify}
                          // required
                          onChange={(e) => {
                            setVerify(e.target.value);
                          }}
                        >
                          <option value="1" selected>
                            OUI
                          </option>
                          <option value="0">NON</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={4} className="formx-groupx" style={{}}>
                      <Form.Group className="mb-4">
                        <Form.Label className="fw-bold">
                          Profils <i className="text-danger">*</i>{" "}
                        </Form.Label>
                        <MultiSelect
                          value={selectedOptionProfils}
                          onChange={(e) => {
                            setSelectedOptionProfils(e.value);
                            handleSelectedProfilsChange(e.value);
                          }}
                          options={dataprofils}
                          optionLabel="label"
                          placeholder="Sélectionner"
                          maxSelectedLabels={10}
                          className="w-full md:w-25rem"
                          style={{ width: "100%", height: "40px" }}
                          filter
                          filterPlaceholder="Rechercher"
                        />
                      </Form.Group>
                    </Col>
                    <div className="col-md-12 mb-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <Form.Label className="fw-bold">
                              Mot de passe <i className="text-danger"></i>
                            </Form.Label>
                            <div className="input-group">
                              <Form.Control
                                className={`border border rounded-2 ${
                                  passwordError ? "is-invalid" : ""
                                }`}
                                onChange={handlePasswordChange}
                                value={password}
                                placeholder="Saisir un mot de passe"
                                name="password"
                                aria-label="password"
                                maxLength={100}
                                minLength={3}
                                type={showPassword ? "text" : "password"}
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? <FiEye /> : <FiEyeOff />}
                              </button>
                            </div>
                            {passwordStrength && (
                              <div className="text-muted">
                                {passwordStrength}
                              </div>
                            )}
                            {passwordError && (
                              <Form.Control.Feedback type="invalid">
                                {passwordError}
                              </Form.Control.Feedback>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <Form.Label className="fw-bold">
                              Confirmation mot de passe <i className="text-danger"></i>
                            </Form.Label>
                            <div className="input-group">
                              <Form.Control
                                className={`border border rounded-2 ${
                                  passwordError ? "is-invalid" : ""
                                }`}
                                onChange={handleConfirmPasswordChange}
                                value={confirmPassword}
                                placeholder="Confirmer le mot de passe"
                                name="confirmPassword"
                                aria-label="confirmPassword"
                                maxLength={100}
                                minLength={3}
                                type={confirmshowPassword ? "text" : "password"}
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() =>
                                  setShowconfirmPassword(!confirmshowPassword)
                                }
                              >
                                {confirmshowPassword ? <FiEye /> : <FiEyeOff />}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                  <Button type="submit" variant="warning me-3">
                    Enregistrer
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => navigate(myroutes.utilisateurs_index)}
                  >
                    Annuler
                  </Button>
                </Form>
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}
          </div>
          {/*//container-fluid*/}
        </div>
        {/*//app-content*/}
      </main>
    </Layout>
  );
}
