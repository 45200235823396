import React from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";
import { getButtonConfig } from "../utils/ButtonUtils";

const ExcelExport = ({ data, fileName }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const date_actuelle = new Date();
    // Récupérer les différentes parties de la date
    const annee = date_actuelle.getFullYear();
    const mois = String(date_actuelle.getMonth() + 1).padStart(2, "0"); // Mois commence à 0, donc on ajoute 1
    const jour = String(date_actuelle.getDate()).padStart(2, "0"); // Ajoute un zéro pour les jours à un chiffre

    // Récupérer les différentes parties de l'heure
    const heures = String(date_actuelle.getHours()).padStart(2, "0");
    const minutes = String(date_actuelle.getMinutes()).padStart(2, "0");
    const secondes = String(date_actuelle.getSeconds()).padStart(2, "0");

    // Combiner les parties pour former le format yyyy-mm-jj hh:mm:ss
    const date_heure_formatee = `${annee}${mois}${jour}${heures}${minutes}${secondes}`;

    saveAs(blob, `${fileName}_${date_heure_formatee}.xlsx`);
  };

  return (
    <Button
      className={`btn btn-sm btn-${getButtonConfig("EXPORT")?.color}`}
      title={`${getButtonConfig("EXPORT")?.text}`}
      onClick={exportToExcel}
    >
      <i className={`fa ${getButtonConfig("EXPORT")?.icon}`}></i> Exporter
    </Button>
  );
};

export default ExcelExport;
