import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { convertDateToTexte } from '../utils/helpers';
import { RadioButton } from 'primereact/radiobutton';
import AppelanteFormContainer from './AppelanteFormContainer';
import IntimeFormContainer from './IntimeFormContainer';
import AssujettiesService from '../services/AssujettiesService';
import CivilitesService from '../services/CivilitesService';
import StatutsjuridiquesService from '../services/StatutsjuridiquesService';

const ConfirmationModal = ({ visible, onHide, onConfirm, addFormData, types_procedures, objets, procedures, allCalendriers, docData, libelleTribunal, assujetties_acteurs}) => {
    const token = useSelector((state) => state.auth.token);

    const renseignement_affaire = useSelector((state) => state.auth.renseignementAffaire);
    const appelantSaisie = useSelector((state) => state.auth.appelants);
    const intimeSaisie = useSelector((state) => state.auth.intimes);

    const [assujetties_not_acteur, setAssujettiesNotActeur] = useState([]);
    //const [assujetties_acteurs, setAssujetties_acteurs] = useState([]);
    const [civilites, setCivilites] = useState([]);
    const [statuts_juridiques, setStatutsJuridiques] = useState([]);

    const [currentDate, setCurrentDate] = useState(null);
    const getNowDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
        let day = date.getDate().toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
        return `${year}-${month}-${day}`; // Retourne la date au format YYYY-MM-DD
    };

    const getTypeProcedure = (id) => {
        const filteredData = types_procedures.find((procedure) => procedure.id == id);
        return filteredData ? filteredData.libelle : null;
    };

    const getProcedure = (id) => {
        const filteredData = procedures.find((procedure) => procedure.id == id);
        return filteredData ? filteredData.libelle : null;
    };

    const getObjet = (id) => {
        const filteredData = objets.find((obj) => obj.id === id);
        return filteredData ? filteredData.libelle : null;
    };

    const getCalendrier = (id) => {
        const filteredData = allCalendriers.find((obj) => obj.id === id);
        return filteredData ? convertDateToTexte(filteredData.jour) : null;
    };

    const fetchAssujettiesNotActeur = async () => {
        await AssujettiesService.index(token)
            .then((response) => {
                //Afficher les assujetties qui sont pas des acteurs
                const filteredData = response.data.data.filter((opt) => opt.is_acteur === parseInt(0));

                const data = filteredData.map((item) => {
                    return {
                        name: item.raison_sociale,
                        id: item.id
                    }
                })
                //console.log(filteredData)
                setAssujettiesNotActeur(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    //get liste civilites
    const fetchCivilites = async () => {
        await CivilitesService.index(token)
            .then((response) => {
                setCivilites(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //Retourner la civilite
    const getCivilite = (id) => {
        if (!Array.isArray(civilites)) {
            console.error('civilites is not an array');
            return null;
        }

        const data = civilites.find((opt) => opt.id === id);
        return data ? data.libelle : null;
    }

    const fetchStatutJuridique = async () => {
        try {
            const response = await StatutsjuridiquesService.index(token);
            if (response.status == 200) {
                setStatutsJuridiques(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    //Retourner le nom de la partie
    const getStatutJuridique = (id) => {
        if (!Array.isArray(statuts_juridiques)) {
            console.error('statuts_juridiques is not an array');
            return null;
        }

        const data = statuts_juridiques.find((opt) => opt.id === id);
        return data ? data.libelle : null;
    }


    //Retourner le nom de la partie
    const getPartie = (assujettie_selected) => {
        if (!Array.isArray(assujetties_not_acteur)) {
            console.error('assujetties_not_acteur is not an array');
            return null;
        }

        const data = assujetties_not_acteur.find((opt) => opt.id === assujettie_selected);
        //console.log(data)
        return data ? data.name : null;
    }

    //Les avocats/acteurs de justice
    /*const fetchAssujetties_acteurs = async () => {
        try {
            const response = await AssujettiesService.index_acteurs(token);

            //Get only Avocat
            const filteredData = response.data.data.filter((item) => item.get_type_acteur?.libelle === "Avocat")

            setAssujetties_acteurs(filteredData);
            //console.log(filteredData)
        } catch (error) {
            console.log(error);
        }
    };*/

    //Retourner le nom des avocats
    const getAvocats = (selectedOptionRepresentePar, assujettiesListe) => {
        if (!Array.isArray(assujettiesListe)) {
            console.error('assujetties_acteurs is not an array');
            return null;
        }

        // Filtrer les assujeties présentes dans selectedOptionRepresentePar
        return assujettiesListe
        .filter(assujettie => selectedOptionRepresentePar.includes(assujettie.id))
        .map(assujettie => assujettie.raison_sociale)
        .join('; ');
    }


    useEffect(() => {
        setCurrentDate(getNowDate());
        fetchAssujettiesNotActeur();
        //fetchAssujetties_acteurs();
        fetchCivilites();
        fetchStatutJuridique();
    }, []);

    return (
        <Dialog
            header="Confirmez-vous l'enregistrement des données ?"
            visible={visible}
            onHide={onHide}
            maximizable
            footer={
                <div>
                    <Button label="NON" className="p-button-warning me-2" onClick={onHide} />
                    <Button label="OUI" type="submit" className="p-button-success" onClick={onConfirm} />
                </div>
            }
        >
            <div className="card">
                <div className="accordion" id="accordionExample">

                    {/** Renseignements sur l'affaire */}

                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button custom-header"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                <span className="fw-bold"> 1. RENSEIGNEMENTS SUR L'AFFAIRE</span>
                            </button>
                        </h2>
                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Label className="fw-bold label-form">
                                            Date de la saisine
                                        </Form.Label> <br />

                                        <Form.Label className="fw-bold label-form">
                                            Objet de la saisine
                                        </Form.Label> <br />

                                        <Form.Label className="fw-bold label-form">
                                            Jugement attaqué, Date et Provenance
                                        </Form.Label> <br />

                                        <Form.Label className="fw-bold label-form">
                                            Type de Procédure
                                        </Form.Label> <br />

                                        <Form.Label className="fw-bold label-form">
                                            Procédure
                                        </Form.Label> <br />

                                        <Form.Label
                                            className="fw-bold label-form">
                                            Référence du dossier/ordonnance
                                        </Form.Label> <br />

                                        <Form.Label className="fw-bold">
                                            Date d'audience
                                        </Form.Label> <br />

                                    </div>
                                    <div className="col-8">
                                        <Form.Control className="border border rounded-2 control-label"
                                            type="text"
                                            readOnly={true} value={currentDate}
                                            disabled />

                                        <Form.Control className="border border rounded-2 control-label"
                                            type="text"
                                            readOnly={true} value={getObjet(renseignement_affaire.objet_id)}
                                            disabled />

                                        <div className="row mt-5 mb-2">
                                            <div className="col-md-4">
                                                <Form.Control className="border border rounded-2 control-label"
                                                    type="text"
                                                    readOnly={true}
                                                    value={renseignement_affaire.jugement_attaque}
                                                    disabled />
                                            </div>
                                            <div className="col-md-4">
                                                <Form.Control className="border border rounded-2 control-label"
                                                    type="text"
                                                    readOnly={true}
                                                    value={renseignement_affaire.date_jugement}
                                                    disabled />
                                            </div>
                                            <div className="col-md-4">
                                                <Form.Control className="border border rounded-2 control-label"
                                                    type="text"
                                                    readOnly={true} value={libelleTribunal}
                                                    disabled />
                                            </div>
                                        </div>
                                        <Form.Control className="border border rounded-2 control-label"
                                            type="text"
                                            readOnly={true} value={getTypeProcedure(renseignement_affaire.type_procedure_id)}
                                            disabled />

                                        <Form.Control className="border border rounded-2 control-label mt-4"
                                            type="text"
                                            readOnly={true} value={getProcedure(renseignement_affaire.procedure_id)}
                                            disabled />

                                        <div className="row mb-2">
                                            <div className="col-md-12 pt-3">
                                                <Form.Control className="border border rounded-2 control-label"
                                                    type="text"
                                                    readOnly={true}
                                                    value={renseignement_affaire.reference_dossier}
                                                    disabled />

                                            </div>
                                        </div>

                                        <Form.Control className="border border rounded-2 control-label"
                                            type="text"
                                            readOnly={true} value={getCalendrier(renseignement_affaire.calendrier_id)}
                                            disabled />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/** End Renseignements sur l'affaire */}

                    {/**Start  Appelantes */}
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed custom-header"
                                type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                2. APPELANT-E-S
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {appelantSaisie.map((option, index) => (
                                    <>
                                        <div className="row border-bottom" key={index}>
                                            <div className="col-4">
                                                {option.assujettie_selected ? <>
                                                    <Form.Label className="fw-bold label-form">
                                                        Appellant
                                                    </Form.Label> <br />
                                                    <Form.Label className="fw-bold label-form">
                                                        Partie(s) et conseil(s)
                                                    </Form.Label> <br />
                                                </> : <>
                                                    <Form.Label className="fw-bold label-form mt-5">
                                                        Appellant
                                                    </Form.Label> <br />
                                                    <Form.Label className="fw-bold label-form mt-5 pt-4">
                                                        Partie(s) et conseil(s)
                                                    </Form.Label>
                                                </>}

                                            </div>

                                            <div className="col-8">
                                                {option.assujettie_selected ? <>
                                                    <Form.Control className="border border rounded-2 control-label"
                                                        type="text"
                                                        readOnly={true} value={getPartie(option.assujettie_selected)}
                                                        disabled />

                                                    <Form.Control className="border border rounded-2 control-label"
                                                        type="text"
                                                        readOnly={true} value={getAvocats(option.selectedOptionRepresentePar, assujetties_acteurs)}
                                                        disabled />
                                                </> : <>
                                                    <div className="card shadow p-3 mt-2">
                                                        <div className="row bg-gray">
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Civilité : </span> {getCivilite(option.civilite_id)}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Nom et Prénoms : </span> {option.nom + " " + option.prenoms}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Dénomination sociale : </span> {option.raison_sociale}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Forme Juridique : </span> {getStatutJuridique(option.statut_juridique_id)}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>RCCM : </span> {option.rccm}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>IFU : </span> {option.ifu}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>E-mail : </span> {option.email}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Téléphone : </span> {option.telephone}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Adresse : </span> {option.adresse}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row fw-bold">
                                                        <div className="col">
                                                            {getAvocats(option.selectedOptionRepresentePar, assujetties_acteurs)}
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>

                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/**End  Appelantes */}

                    {/** Intimées */}

                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed custom-header"
                                type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree">
                                3. INTIME-E-S
                            </button>
                        </h2>
                        <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                            <div className="accordion-body">
                            {intimeSaisie.map((option, index) => (
                                    <>
                                        <div className="row border-bottom" key={index}>
                                            <div className="col-4">
                                                {option.assujettie_selected ? <>
                                                    <Form.Label className="fw-bold label-form">
                                                        Intimé
                                                    </Form.Label> <br />
                                                    <Form.Label className="fw-bold label-form">
                                                        Partie(s) et conseil(s)
                                                    </Form.Label> <br />
                                                </> : <>
                                                    <Form.Label className="fw-bold label-form mt-5">
                                                        Intimé
                                                    </Form.Label> <br />
                                                    <Form.Label className="fw-bold label-form mt-5 pt-4">
                                                        Partie(s) et conseil(s)
                                                    </Form.Label>
                                                </>}

                                            </div>

                                            <div className="col-8">
                                                {option.assujettie_selected ? <>
                                                    <Form.Control className="border border rounded-2 control-label"
                                                        type="text"
                                                        readOnly={true} value={getPartie(option.assujettie_selected)}
                                                        disabled />

                                                    <Form.Control className="border border rounded-2 control-label"
                                                        type="text"
                                                        readOnly={true} value={getAvocats(option.selectedOptionRepresentePar, assujetties_acteurs)}
                                                        disabled />
                                                </> : <>
                                                    <div className="card shadow p-3 mt-2">
                                                        <div className="row bg-gray">
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Civilité : </span> {getCivilite(option.civilite_id)}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Nom et Prénoms : </span> {option.nom + " " + option.prenoms}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Dénomination sociale : </span> {option.raison_sociale}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Forme Juridique : </span> {getStatutJuridique(option.statut_juridique_id)}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>RCCM : </span> {option.rccm}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>IFU : </span> {option.ifu}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>E-mail : </span> {option.email}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Téléphone : </span> {option.telephone}
                                                            </div>
                                                            <div className="col-6">
                                                                <span className='fw-bold'>Adresse : </span> {option.adresse}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row fw-bold">
                                                        <div className="col">
                                                            {getAvocats(option.selectedOptionRepresentePar, assujetties_acteurs)}
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>

                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/**End  Intimées */}

                    {/**Acte de saisine */}

                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed custom-header"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                        >
                          {addFormData.type_procedure_id != 3 ? '4.' : '2.'} ACTE(S) DE VOTRE
                          SAISINE
                        </button>
                      </h2>
                      <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="mb-2" style={{ fontSize: '16px' }}>Ajouter le(s)
                            document(s)
                            nécessaires pour votre saisine et adapté(s) à votre type de
                            procédure.</p>
                          <strong className="" style={{ fontStyle: 'italicx', color: 'red' }}>NB :
                            L'acte autorisant la procédure est obligatoire pour la procédure
                            AVENIR D'AUDIENCE</strong>
                          <p className="mt-4"
                             style={{ fontSize: '16px', fontWeight: 'bold' }}> Liste des fichiers joints
                            (PDF)</p>

                          {/**Start bloc */}                          
                          <div className="border-success-subtle border-top py-2">
                            {docData.map((item, index) => (
                                item.file && (
                                    <div key={index}>
                                      <p>Nom du fichier : {item.file.name}</p>
                                    </div>
                                )
                            ))}
                          </div>

                          {/**End bloc */}

                        </div>
                      </div>
                    </div>
                    {/**End Acte de saisine */}

                </div>
            </div>


        </Dialog>
    );
};

export default ConfirmationModal;
