import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.css";
import DossiersService from "../../../services/DossiersService";
import StatutsdossiersService from "../../../services/StatutsdossiersService";
import CategoriesService from "../../../services/CategoriesService";
import ObjetsService from "../../../services/ObjetsService";
import { Dialog } from "primereact/dialog";
import LayoutActeur from "../inc/layout_acteur";
import ChambresService from "../../../services/ChambresService";
import StatutsService from "../../../services/StatutsService";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import { ProgressSpinner } from "primereact/progressspinner";
import PaiementsService from "../../../services/PaiementsService";
import { RadioButton } from "primereact/radiobutton";
import ModesPaiementsService from "../../../services/ModesPaiementsService";
import { set } from "date-fns";
import {
  convertDateToTexte,
  downloadSingleFile,
  getLatestDate,
  getPaiementConfig,
  setDataTable,
} from "../../../utils/helpers";
import { app_url } from "../../../services/http";
import DossierspartiesdocumentsService from "../../../services/DossierspartiesdocumentsService";
import { saveAs } from "file-saver";
import { getButtonConfig } from "../../../utils/ButtonUtils";

export default function DossiersIndexActeur() {
  //##########################/// METHODES ##########################///
  const [dossiers, setDossiers] = useState([]);
  const [statuts, setStatuts] = useState([]);
  const [pays, setPays] = useState([]);
  const [chambres, setChambres] = useState([]);
  const [chambre_id, setChambre_id] = useState("");
  const [utilisateurs, setUtilisateurs] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [visible, setVisible] = useState(false);
  const [paysTrouve, setPaysTrouve] = useState([]);
  const [statutTrouve, setStatutTrouve] = useState([]);
  const [objets, setObjets] = useState([]);
  const [statutsdossiers, setStatutsdossiers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dossiersfiles, setDossiersfiles] = useState([]);
  const [showModePaiement, setshowModePaiement] = useState(false);
  const [modespaiements, setModesPaiements] = useState([]);
  const [mode_paiement_id, setModePaiement_Id] = useState("");
  const [montant_payer, setMontantPayer] = useState(10000);
  const [reference_dossier, setReferenceDossier] = useState("");
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false); // État pour le chargement
  const [statut_paiement, setStatutPaiement] = useState("");

  const [modalLabel, setModalLabel] = useState("");
  const [modalObjet, setModalObjet] = useState([]);
  const [dossierspartiesdocuments, setDossierspartiesdocuments] = useState([]);

  const [showModal, setShowModal] = useState(false); // État pour contrôler la visibilité du modal
  const [disablePaiementButton, setDisablePaiementButton] = useState(false);

  const handleShowModal = () => setShowModal(true); // Fonction pour afficher le modal
  const handleCloseModal = () => {
    setShowModal(false);
    setshowModePaiement(false);
  }; // Fonction pour fermer le modal

  const profils = useSelector((state) => state.auth.profil);

  const hasAccessToSensitiveInfo = profils.includes("USER");
  const hasAccessToSensitiveInfoActeur = profils.includes("AJ");

  const dispatch = useDispatch();

  //get liste statuts dossier
  const fetchStatutsdossiers = async () => {
    await StatutsdossiersService.index(token)
      .then((res) => {
        setStatutsdossiers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatutLibelle = (statut_dossier_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_dossier_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const statutDossier = statutsdossiers.find((item) => {
      return item.id == idToFind;
    });

    return statutDossier ? statutDossier.libelle : "N/A";
  };

  //get liste chambres
  const fetchChambres = async () => {
    await ChambresService.index(token)
      .then((res) => {
        setChambres(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste categories dossiers
  const fetchObjets = async () => {
    await ObjetsService.index(token)
      .then((res) => {
        setObjets(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getObjets = (objet_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(objet_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Objet = objets.find((item) => {
      return item.id == idToFind;
    });

    return Objet ? Objet.libelle : "N/A";
  };

  //get liste categories dossiers
  const fetchCategories = async () => {
    await CategoriesService.index(token)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste dossiers
  const fetchDossiers = async () => {
    try {
      const response = await DossiersService.listeDossierPayer(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDossiers(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    setDataTable();
  };

  const fetchStatuts = async () => {
    await StatutsService.index(token)
      .then((res) => {
        setStatuts(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste mode de paiement
  const fetchModepaiements = async () => {
    await ModesPaiementsService.index(token)
      .then((response) => {
        //Récupérer uniquement les modes de paiements actif
        setModesPaiements(
          response.data.data.filter((mode) => mode.statut_id === 1)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Fonction pour verifier le statut du paiement pour un dossier
  const checkStatutPaiement = (statut_id) => {
    const dataFiltrer = statuts.filter(
      (option) => option.id == parseInt(statut_id)
    );
    const libelle = dataFiltrer.length > 0 ? dataFiltrer[0].libelle : null;
    if (libelle == "PAYE") {
      return false;
    }

    return true;
  };

  const lauchPaiement = async (ref_dossier, statut_id) => {
    setDisablePaiementButton(true); //Désactiver le button de paiement
    setReferenceDossier(ref_dossier);
    const dataFiltrer = statuts.filter(
      (option) => parseInt(option.id) == parseInt(statut_id)
    );
    const libelle = dataFiltrer.length > 0 ? dataFiltrer[0].libelle : null;
    setStatutPaiement(libelle);
    //alert(ref_dossier)

    //Verifier si le paiement était déjà initié
    if (libelle == "INITIE") {
      //Lancer le paiement avec KKIAPAY
      storePaiement(ref_dossier);
    } else {
      setshowModePaiement(true);
    }
  };

  const storePaiement = async (ref_dossier) => {
    if (showModePaiement == true && mode_paiement_id == "") {
      return false;
    }
    setshowModePaiement(false);
    //alert(ref_dossier)
    //console.log(reference_dossier)
    //Afficher le hook de paiement
    //Cle kiosque digital
    var config = getPaiementConfig();
    openKkiapayWidget({
      amount: montant_payer,
      position: "center",
      data: { reference_dossier: ref_dossier, mode_paiement_id },
      name: auth.user.fullname,
      theme: "green",
      api_key: config.key,
      sandbox: config.isSandbox,
      email: auth.user.email,
      phone: parseInt(auth.user.telephone),
    });
  };

  //Get kkiapay reponse after paiement success
  const successHandler = async (response) => {
    setLoading(true); // Début du chargement

    //Recuperation du code de la transaction de kkiapay
    var code_transaction = response.transactionId;
    // Enregistrer le paiement
    let paiementsInfo = {
      montant: montant_payer,
      reference_dossier: response.data.reference_dossier,
      telephone: auth.user.telephone,
      modepaiement_id: response.data.mode_paiement_id,
    };
    await PaiementsService.store(code_transaction, paiementsInfo, auth.token)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Enregistrement réussi",
            text: res.data.data.transaction.message,
          });
          // Actualiser la page
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else if (res.data.data.echec) {
          setLoading(false); // Fin du chargement en cas d'erreur de validation

          Swal.fire({
            icon: "warning",
            title: "Une erreur s'est produite !",
            text: res.data.data.echec,
          });
        } else if (res.data.erreur) {
          setLoading(false); // Fin du chargement en cas d'erreur de validation

          Swal.fire({
            icon: "error",
            title: "Erreur!",
            text: res.data.message,
          });
        }
      })
      .catch((error) => {
        setLoading(false); // Fin du chargement en cas d'erreur de validation
        console.log("Error", error);
      });
  };

  //get liste dossiers
  const fetchDossiersfiles = async () => {
    try {
      const response = await DossierspartiesdocumentsService.index(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDossierspartiesdocuments(responseData.data);
        //console.log("responseData.datafiles", responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };

  //redirection sur la page edit sans afficher les id dans l'url
  const goToEdit = (param) => {
    navigate(myroutes.dossiers_edit, { state: { ref: param } });
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        DossiersService.delete(param, token)
          .then((response) => {
            if (response.data.erreur) {
              Swal.fire({
                icon: "error",
                text: response.data.erreur,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              fetchDossiers();
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.message,
            });
            console.log(e);
          });
      }
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSetModePaiement = (e) => {
    setModePaiement_Id(e.target.value);
  };

  //Afficher le modal de details
  const handleShowDetails = (option) => {
    setModalLabel(option.reference_dossier);
    const filteredData = dossierspartiesdocuments.filter(
      (opt) => parseInt(opt.dossier_id) === option.id
    );
    setModalObjet(filteredData);
    //console.log(filteredData)
    handleShowModal();
  };

  const handleDownload = (path) => {
    //e.preventDefault();
    saveAs(`${app_url}storage/${path}`, path);
  };

  const goToShow = (ref) => {
    navigate(`${myroutes.dossiersacteurs_show}/${ref}`);
  };

  useEffect(() => {
    fetchDossiers();
    fetchStatutsdossiers();
    //fetchObjets();
    fetchStatuts();
    //fetchCategories();
    //fetchChambres();
    //fetchModepaiements();
    fetchDossiersfiles();
    $("#dossiers-nav").addClass("show");
    addKkiapayListener("success", successHandler);
    return () => {
      removeKkiapayListener("success", successHandler);
    };
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <LayoutActeur>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard_acteur}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">Dossiers </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                {!profils.includes("HS") &&
                  !profils.includes("EXP") &&
                  !profils.includes("PR") &&
                  !profils.includes("AGR") &&
                  !profils.includes("CCC") && (
                    <h3 className="app-page-title mb-0">
                      Liste des dossiers{" "}
                      <Link
                        className={`btn btn-sm btn-${
                          getButtonConfig("CREATE")?.color
                        }`}
                        to={myroutes.dossiersacteurs_creer}
                        title="Ajouter"
                      >
                        <i className={`fa ${
                                      getButtonConfig("CREATE")?.icon
                                    }`}></i>&nbsp;Ajouter
                      </Link>{" "}
                    </h3>
                  )}
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Référence Affaire</th>
                        <th className="">Jugement attaqué</th>
                        <th className="">Parties</th>
                        <th className="">Date d'audience</th>
                        <th className="">Date de la saisine</th>
                        <th className="">Statut</th>
                        <th className="">Type procédure</th>
                        {!profils.includes("PDT") &&
                          !profils.includes("AGEC") &&
                          !profils.includes("CCC") && (
                            <th className="" style={{maxWidth: "100px"}}>Action</th>
                          )}
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {dossiers.length > 0 &&
                        dossiers.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell fw-bold">
                              <Link
                                to={`${myroutes.dossiersacteurs_show}/${item.ref}`}
                                title="Voir"
                              >
                                <b>{item.reference_dossier} </b>
                              </Link>
                            </td>
                            <td className="cell">
                              {item.jugement_attaque} du{" "}
                              {formatDate(item.date_jugement)} au{" "}
                              {item.get_tribunal?.libelle}
                            </td>
                            <td className="cell">
                              {item.get_partie.length > 0 &&
                                Object.values(
                                  item.get_partie.reduce((acc, item) => {
                                    const type_partie_id = item.type_partie_id;
                                    if (!acc[type_partie_id]) {
                                      acc[type_partie_id] = [];
                                    }
                                    acc[type_partie_id].push(item);
                                    return acc;
                                  }, {})
                                ).map((group, idx) => (
                                  <div key={idx} style={{ fontSize: "12px" }}>
                                    <span className="text-center">
                                      {group[0].type_partie_id != 1 && "C/"}
                                    </span>
                                    <ul>
                                      {group.map((item, index) =>
                                        item.is_avocat === 0 && <><li key={index}>
                                        {item.get_assujetie.raison_sociale}
                                      </li></>
                                      )}
                                    </ul>
                                  </div>
                                ))}
                            </td>
                            
                            <td className="cell">
                            {item &&
                                item.get_date_audience &&
                                item.get_date_audience.length > 0 &&
                                (() => {
                                  const latestDate = getLatestDate(
                                    item.get_date_audience
                                  );
                                  return latestDate;
                                })()}
                            </td>
                            <td className="cell">
                              <span>
                                {dayjs(item.created_at).format("DD/MM/YYYY")}
                              </span>
                            </td>

                            <td className="cell">
                              <span
                                className={`badge ${
                                  getStatutLibelle(item.statut_dossier_id) ===
                                  "Nouveau"
                                    ? "text-bg-success"
                                    : getStatutLibelle(
                                        item.statut_dossier_id
                                      ) === "Renvoyé"
                                    ? "text-bg-danger"
                                    : "text-bg-secondary"
                                }`}
                              >
                                {getStatutLibelle(item.statut_dossier_id)}
                              </span>
                            </td>
                            <td className="cell">
                              <span className="badge bg-secondary">
                                {item.get_procedure.get_type_procedure
                                  ? item.get_procedure.get_type_procedure
                                      .libelle
                                  : "N/A"}
                              </span>
                              {/***<span className="badge bg-primary">{item.parent_id && "PROCEDURE DEP"}</span> */}
                            </td>

                            {!profils.includes("PDT") ||
                            !profils.includes("AGEC") ||
                            !profils.includes("CCC") ? (
                              <td className="cell">
                                {item.get_paiement.length > 0 &&
                                  item.get_paiement.map(
                                    (opt, i) =>
                                      checkStatutPaiement(opt.statut_id) && (
                                        <Button
                                          key={i}
                                          disabled={disablePaiementButton}
                                          className="btn btn-lg btn-success icon-font me-2 mb-2 "
                                          onClick={() =>
                                            lauchPaiement(
                                              item.reference_dossier,
                                              opt.statut_id
                                            )
                                          }
                                          title="Procéder au paiement"
                                        >
                                          <i className="bi bi-wallet"></i> Payer
                                        </Button>
                                      )
                                  )}
                                {item.get_paiement.length == 0 && (
                                  <Button
                                    variant="success"
                                    className="icon-font me-2 mb-2 "
                                    onClick={() =>
                                      lauchPaiement(item.reference_dossier)
                                    }
                                    title="Procéder au paiement"
                                  >
                                    <i className="bi bi-wallet"></i> Payer{" "}
                                  </Button>
                                )}
                                <Button
                                      className="btn btn-sm btn-info icon-font me-2 mb-2 "
                                      onClick={() => goToShow(item.ref)}
                                      title="Consulter le dossier"
                                    >
                                      <i className="bi bi-eye-fill text-white h6"></i>
                                    </Button>
                                <Button
                                  variant="secondary"
                                  className="icon-font me-2 mb-2"
                                  onClick={(e) => handleShowDetails(item)}
                                  title="Pièces jointes du dossier"
                                >
                                  <i className="bi bi-file-earmark-font-fill"></i>
                                </Button>
                              </td>
                            ) : null}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
            <Dialog
              header="Mode de paiement"
              visible={showModePaiement}
              onHide={handleCloseModal}
              style={{
                width: "500px",
                minHeight: "250px",
                borderBottom: "1px solid grey",
                color: "GrayText",
              }}
              headerStyle={{ background: "#f0f0f0" }}
            >
              <div className="mb-3">
                <strong className="mb-4 me-3">
                  Mode de paiements disponibles
                </strong>
              </div>
              <div className="row mb-4 pt-2 p-fluid">
                {modespaiements.length > 0 &&
                  modespaiements.map((item, index) => (
                    <div className="col-md-4" key={index}>
                      <div className="p-field">
                        <RadioButton
                          className="me-2"
                          inputId={`option-${index}`}
                          required
                          value={item.code}
                          onChange={handleSetModePaiement}
                          checked={mode_paiement_id === item.code}
                        />
                        <label htmlFor={`option-${index}`} className="fw-bold">
                          {item.libelle}
                        </label>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="p-dialog-footerx mt-4">
                <Button
                  variant="secondary"
                  className="me-2"
                  onClick={handleCloseModal}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="success"
                  className=""
                  onClick={storePaiement}
                >
                  Confirmer
                </Button>
              </div>
            </Dialog>

            <Modal show={loading} backdrop="static" keyboard={false} centered>
              <Modal.Body className="text-center">
                <ProgressSpinner />
                <p className="my-3 fw-bold" style={{ fontSize: "18px" }}>
                  Veuillez patienter pendant l'enregistrement de vos données...
                </p>
              </Modal.Body>
            </Modal>

            {/* Modal */}
            <Dialog
              header={"Détails sur l'affaire " + modalLabel}
              visible={showModal}
              maximizable
              onHide={handleCloseModal}
              style={{
                width: "700px",
                borderBottom: "1px solid grey",
                color: "GrayText",
              }}
              headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
            >
              <div className="p-fluid">
                <div className="row">
                  <hr />
                  <p className="text-center fw-bold">
                    Documents relatifs aux dossiers{" "}
                  </p>
                </div>
              </div>
              <div className="row">
                {modalObjet.map((item, index) => (
                  <>
                    <p>
                      <b>Auteur : </b> {item.get_createdby.nom} <br />
                      <b>Type document : </b> {item.get_type_document.libelle}
                      <br />
                      <b>Date d'envoi : </b>{" "}
                      {dayjs(item.created_at).format("DD-MM-YYYY à H:m:s")}
                    </p>
                    <a
                      href="#"
                      onClick={() => downloadSingleFile(item.path, token)}
                      className="text-primary fw-bold"
                    >
                      Télécharger le fichier
                    </a>
                    <hr />
                  </>
                ))}
              </div>

              <div className="p-dialog-footer mt-3">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Fermer
                </Button>
              </div>
            </Dialog>
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </LayoutActeur>
  );
}
