import React from "react";
import { useEffect, useState } from "react";
import Layout from "./include/layout";
import { Link, useNavigate } from "react-router-dom";
import DossiersService from "../../services/DossiersService";
import { useSelector } from "react-redux";
import DatesaudiencesService from "../../services/DatesaudiencesService";
import UtilisateursService from "../../services/UtilisateursService";
import StatutsdossiersService from "../../services/StatutsdossiersService";
import CategoriesdossiersService from "../../services/ObjetsService";
import CategoriesService from "../../services/CategoriesService";
import $ from "jquery";
import dayjs from "dayjs";
import { Chart } from "primereact/chart";
import { Timeline } from "primereact/timeline";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faBriefcase, faSchool, faStar } from '@fortawesome/free-solid-svg-icons';
import CalendarComponent from "../../components/CalendarComponent";
import { myroutes } from "../../routes/webroute";
import "../assets/css/stats.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getColorStatus } from "../../utils/helpers";

export default function Dashboard() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [filter, setFilter] = useState("today");
  const auth = useSelector((state) => state.auth);
  const profils = useSelector((state) => state.auth.profil);

  const [dossiers, setDossiers] = useState([]);
  const [dossierscount, setDossierscount] = useState(0);
  const [datesaudiences, setDatesaudiences] = useState(0);
  const [utilisateurs, setUtilisateurs] = useState(0);

  const [categoriesdossiers, setCategoriesdossiers] = useState([]);
  const [statutsdossiers, setStatutsdossiers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [events, setEvents] = useState([]);
  const [chambres, setChambres] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const profil_habilitation = useSelector(
    (state) => state.auth.profil_habilitation
  );

  const [dossierData, setDossierData] = useState({
    saisines: 0,
    nouveaudossiers: 0,
    dossiersrenvoyes: 0,
    dossiersendelibere: 0,
    dossiersvides: 0,
    dossiersradies: 0,
    dossierspayes: 0,
  });

  //get liste des dossiers par chambre
  const fetchStatsByChambre = async () => {
    await DossiersService.countDossiersByChambre(token)
      .then((res) => {
        setChambres(res.data.data);
        //console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*const events = [
    { status: ' Lorem ipsum dolor sit amet, consectetur ', date: '15/10/2020 10:30', icon: 'bi bi-cart-check-fill', color: '#9C27B0', image: 'game-controller.jpg' },
    { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
    { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
    { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' },
  ];*/

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  //get liste statuts
  const fetchStatutsdossiers = async () => {
    await StatutsdossiersService.index(token)
      .then((res) => {
        setStatutsdossiers(res.data.data);
        //console.log("res.data.data", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatutLibelle = (statut_dossier_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_dossier_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const statutDossier = statutsdossiers.find((item) => {
      return item.id == idToFind;
    });

    return statutDossier ? statutDossier.libelle : "N/A";
  };

  //get liste categories dossiers
  const fetchCategoriesdossiers = async () => {
    await CategoriesdossiersService.index(token)
      .then((res) => {
        setCategoriesdossiers(res.data.data);
        // console.log("res.data.datacd", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLibelleCategorieDossier = (categorie_dossier_id) => {
    // Vérifie si 'categoriesdossiers' est défini
    if (!categoriesdossiers) {
      console.error("La liste des catégories de dossiers n'est pas définie.");
      return false;
    }

    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(categorie_dossier_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const categorieDossier = categoriesdossiers.find((item) => {
      return item.id === idToFind;
    });

    return categorieDossier ? categorieDossier.libelle : "";
  };

  //get liste categories dossiers
  const fetchCategories = async () => {
    await CategoriesService.index(token)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste dossiers
  const fetchDossiers = async () => {
    try {
      const response = await DossiersService.listeDossierPayer(token);
      const dossiersData = response.data.data;
      setDossiers(dossiersData);

      //Recupérer les dossiers sur lesquels il y a eu des changements
      //const filteredData = dossiersData.filter((option) => option.updated_by != null)

      const dataMap = dossiersData.map((item) => {
        return {
          status: item.reference_dossier,
          date: dayjs(item.updated_at).format("DD-MM-YYYY à H:m:s"),
          id: item.id,
          color: "#673AB7",
        };
      });
      //console.log(filteredData)
      setEvents(dataMap.slice(0, 3));
    } catch (error) {
      console.error("Erreur lors de la récupération des dossiers:", error);
    }
  };

  //get liste dossiers
  const fetchDossierscount = async () => {
    try {
      const response = await DossiersService.listeDossierPayer(token);
      if (response.data && response.data.data) {
        const dossiersDatacount = response.data.data.length;
        setDossierscount(dossiersDatacount);
        //console.log(dossiersDatacount)
      } else {
        console.error("La réponse de l'API ne contient pas de données.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des dossiers:", error);
    }
  };

  //get liste dates audiences
  const fetchDatesaudiences = async () => {
    try {
      const response = await DatesaudiencesService.index(token);
      if (response.data && response.data.data) {
        const dossiersData = response.data.data.length;
        setDatesaudiences(dossiersData);
        // console.log("Dossiers", dossiersData);
      } else {
        console.error("La réponse de l'API ne contient pas de données.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des dossiers:", error);
    }
  };

  //get liste utilisateurs
  const fetchUtilisateurs = async () => {
    try {
      const response = await UtilisateursService.index(token);
      if (response.data && response.data.data) {
        const utilisateursData = response.data.data.length;
        setUtilisateurs(utilisateursData);
      } else {
        console.error("La réponse de l'API ne contient pas de données.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const fetchDossierStats = async () => {
    try {
      const response = await DossiersService.countDossiersByStatut(token);
      const responseData = response.data.data;

      setDossierData({
        saisines: responseData.saisines,
        nouveaudossiers: responseData.nouveaudossiers,
        dossiersrenvoyes: responseData.dossiersrenvoyes,
        dossiersendelibere: responseData.dossiersendelibere,
        dossiersvides: responseData.dossiersvides,
        dossiersradies: responseData.dossiersradies,
        dossierspayes: responseData.dossierspayes,
      });

      // Une fois que la première fonction est terminée, exécuter la deuxième fonction
      const data = {
        datasets: [
          {
            data: [
              responseData?.nouveaudossiers ?? 0,
              responseData?.dossiersrenvoyes ?? 0,
              responseData?.dossiersendelibere ?? 0,
              responseData?.dossiersvides ?? 0,
              responseData?.dossiersradies ?? 0,
            ].filter((value) => typeof value === "number"), // Filtrer pour ne garder que les nombres
            backgroundColor: [
              /*documentStyle.getPropertyValue('--green-500'),
              documentStyle.getPropertyValue('--blue-500'),
              documentStyle.getPropertyValue('--orange-500'),
              documentStyle.getPropertyValue('--bluegray-500'),
              documentStyle.getPropertyValue('--red-500'),*/
              "#378006",
              "#dc3545",
              "#FF9800",
              "#5c636a",
              "#7DCBC9",
            ],
            label: "Dossiers",
          },
        ],
        labels: ["Nouveau", "Renvoyé", "En délibéré", "Vidé", "Radié"].filter(
          (label) => typeof label === "string"
        ), // Filtrer pour ne garder que les chaînes de caractères
      };

      setChartData(data || {});

      //console.log(data)

      const options = {
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          r: {
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };

      setChartOptions(options);
    } catch (error) {
      console.error("Erreur lors de la récupération des statistiques :", error);
    }
  };

  const goToDossier = (dossier_ref) => {
    navigate(`${myroutes.dossiers_show}/${dossier_ref}`);
  };

  useEffect(() => {
    fetchDossiers();
    fetchStatutsdossiers();
    fetchCategoriesdossiers();
    fetchDatesaudiences();
    fetchCategories();
    fetchUtilisateurs();
    //fetchDossierscount();
    $("#submenu-4").addClass("show");
    fetchStatsByChambre();
    fetchDossierStats();
  }, []);
  ///############### END METHODES #############################//

  return (
    <Layout>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Tableau de bord</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={myroutes.dashboard}>Accueil</Link>
              </li>
              <li className="breadcrumb-item active">Tableau de bord</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="row g-3">
                {/* Dossiers */}
                <div className="col">
                  <Link
                    to={
                      profil_habilitation.includes("INTERFACE-ADMIN") &&
                      !profils.includes("PDT")
                        ? myroutes.saisines_index
                        : "#"
                    }
                  >
                    <div className="card info-card text-center">
                      <div className="card-body">
                        <div className="card-icon bg-primary text-white rounded-circle mx-auto mb-3">
                          <i className="bi bi-card-list"></i>
                        </div>
                        <h5 className="card-title">Dossiers enrôlés</h5>
                        <h6 className="card-value">
                          {dossierData.dossierspayes ?? 0}
                          <span
                            className="text-muted"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            / {dossierData.saisines ?? 0}
                          </span>
                        </h6>
                        <span className="text-muted small">Total</span>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-success text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-calendar"></i>
                      </div>
                      <h5 className="card-title">Nouveaux dossiers</h5>
                      <h6 className="card-value">
                        {dossierData.nouveaudossiers ?? 0}
                      </h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-warning text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-card-checklist"></i>
                      </div>
                      <h5 className="card-title">Dossiers en délibéré</h5>
                      <h6 className="card-value">
                        {dossierData.dossiersendelibere ?? 0}
                      </h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-danger text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-file-earmark-font"></i>
                      </div>
                      <h5 className="card-title">Dossiers renvoyés</h5>
                      <h6 className="card-value">
                        {dossierData.dossiersrenvoyes ?? 0}
                      </h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-secondary text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-calendar2-x"></i>
                      </div>
                      <h5 className="card-title">Dossiers radiés et vidés</h5>
                      <h6 className="card-value">
                        {dossierData.dossiersradies ?? 0} /{" "}
                        {dossierData.dossiersvides ?? 0}
                      </h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                {/* End dossiers */}

                {profils.includes("PDT") ? (
                  <>
                    <hr />
                    {/**Calendar */}
                    <div className="col-md-12">
                      <div className="card recent-sales overflow-auto">
                        <div className="card-body">
                          <h5 className="card-title">
                            Nombre de dossiers traités par chambre
                          </h5>
                          <div className="row" style={{ fontSizex: "14px" }}>
                            {chambres.map((item, index) => (
                              <div className="col-md-4" key={index}>
                                <div className="card info-card revenue-cardx">
                                  <div className="card-body">
                                    <h5 className="card-title">
                                      {item.libelle} <span></span>
                                    </h5>

                                    <div className="d-flex align-items-center">
                                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                        <i className="bi bi-bar-chart-fill text-warning"></i>
                                      </div>
                                      <div className="ps-3">
                                        <h6>{item.total_dossiers}</h6>
                                        <span className="text-muted small pt-2 ps-1">
                                          Total
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/**End Calendar */}
                  </>
                ) : (
                  <>
                    {/**Calendar */}
                    <div className="col-md-12">
                      <div className="card recent-sales overflow-auto">
                        <div className="card-body">
                          <h5 className="card-title">
                            Calendrier des audiences
                          </h5>
                          <div className="ps-3" style={{ fontSizex: "14px" }}>
                            <CalendarComponent />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/**End Calendar */}
                    {/* Recent Sales */}
                    <div className="col-md-8 ">
                      <div className="card info-card recent-sales overflow-auto">
                        <div className="card-body">
                          <h5 className="card-title">
                            Derniers dossiers enrôlés
                          </h5>

                          <table className="table table-hover app-table-hover mb-0 data-table">
                            <thead
                              className="table-success mt-2"
                              style={{ backgroundColor: "#e0f8e9" }}
                            >
                              <tr>
                                <th>N°</th>
                                <th>Date</th>
                                <th>Réf. dossier</th>
                                <th>Jugement attaqué</th>
                                <th>Procédure</th>
                              </tr>
                            </thead>
                            <tbody
                              className="table"
                              style={{ textAlign: "left" }}
                            >
                              {dossiers.length > 0 &&
                                // Si des données sont disponibles, affichez les dossiers
                                dossiers.slice(0, 10).map((item, index) => (
                                  <tr key={index}>
                                    <td className="cell">{index + 1}</td>
                                    <td>
                                      {dayjs(item.created_at).format(
                                        "YYYY-MM-DD H:m:s"
                                      )}
                                    </td>
                                    <td className="cell">
                                      <div className="d-flex">
                                        {profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("GEC") ? (
                                          <Link>
                                            <b
                                              onClick={() =>
                                                goToDossier(item.ref)
                                              }
                                              title="Afficher le dossier"
                                            >
                                              {item.reference_dossier}
                                            </b>
                                          </Link>
                                        ) : (
                                          <b>{item.reference_dossier}</b>
                                        )}
                                      </div>
                                    </td>
                                    <td className="cell">
                                      {item.jugement_attaque} du{" "}
                                      {formatDate(item.date_jugement)} au{" "}
                                      {item.get_tribunal?.libelle}
                                    </td>
                                    <td className="cell">
                                      <span className="badge text-bg-secondary">
                                        {item.get_procedure?.libelle}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* End Recent Sales */}

                    <div className="col-md-4">
                      <div
                        className="card info-card sales-card"
                        style={{
                          minHeight: "380px",
                          backgroundColorx: "#1f84d621",
                        }}
                      >
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{
                              fontSize: "14px",
                              borderBottom: "1px solid rgba(1, 41, 112, 0.2)",
                            }}
                          >
                            Activités sur les dossiers
                          </h5>
                          <VerticalTimeline
                            layout="1-column"
                            lineColor={"grey"}
                          >
                            {events.map((event, index) => (
                              <VerticalTimelineElement
                                key={index}
                                contentStyle={{
                                  textAlign: "left",
                                  paddingLeft: "20px",
                                  backgroundColor: "#3b7d821e",
                                }}
                                iconStyle={{
                                  background: "green",
                                  color: "#fff",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                contentArrowStyle={{
                                  borderRight: "7px solid #1f84d610",
                                }}
                                contentArrowOffset={1}
                                //iconStyle={{ background: event.color || 'rgb(33, 150, 243)', color: '#fff' }}
                                date={event.date}
                                icon={<i className="bi bi-bell-fill"></i>}
                              >
                                <p
                                  className="vertical-timeline-element-title"
                                  style={{ fontSize: "14px" }}
                                >
                                  {event.status}
                                </p>
                                {/* Ajoutez le contenu supplémentaire ici si nécessaire */}
                              </VerticalTimelineElement>
                            ))}
                          </VerticalTimeline>
                        </div>

                        {/* End Activités sur les dossiers */}
                      </div>
                      <div
                        className="card info-card sales-card"
                        style={{ height: "380px" }}
                      >
                        {/* Statistiques par statut */}
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{
                              fontSize: "14px",
                              borderBottom: "1px solid rgba(1, 41, 112, 0.2)",
                            }}
                          >
                            Statistiques par statut de dossier
                          </h5>
                          <Chart
                            type="polarArea"
                            data={chartData}
                            options={chartOptions}
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "300px",
                            }}
                          />
                        </div>
                        {/* End Statistiques par statut */}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* End Left side columns */}
            </div>

            {/* End Recent Sales */}
          </div>
        </section>
      </main>
      {/* End #main */}
    </Layout>
  );
}
