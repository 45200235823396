import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../include/layout";
import $ from "jquery";
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import CalendriersService from "../../../../services/CalendriersService";
import {
  afficherMessagesSuccess,
  afficherOneMessageError,
  convertDateToTexte,
  copyToClipboard,
  setDataTable,
} from "../../../../utils/helpers";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import { getButtonConfig } from "../../../../utils/ButtonUtils";
import ExcelExport from "../../../../components/ExcelExport";

export default function CalendriersIndex() {
  //##########################/// METHODES ##########################///
  const [calendriers, setCalendriers] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [calendriersToExport, setCalendriersToExport] = useState([]);
  const [file, setFile] = useState("");
  const [loadingImport, setLoadingImport] = useState(false);

  const handleCopyReference = (reference) => {
    copyToClipboard(reference);
  };

  //get liste calendriers
  const fetchCalendriers = async () => {
    try {
      const response = await CalendriersService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        setCalendriers(responseData.data); //
        setCalendriersToExport(
          responseData.data.map((item, index) => {
            return {
              ID: ++index,
              Chambre: item.get_chambre?.libelle,
              Jours: convertDateToTexte(item.jour),
              "Année juridique": item.get_calendrier_global?.annee,
            };
          })
        );
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  //redirection sur la page edit sans afficher les id dans l'url
  const goToEdit = (param) => {
    navigate(myroutes.calendriers_edit, { state: { ref: param } });
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        CalendriersService.delete(param, token)
          .then((response) => {
            if (response.data.status !== true) {
              Swal.fire({
                icon: "error",
                text: response.data.erreur,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              fetchCalendriers();
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.response.data.message,
            });
            console.log(e);
          });
      }
    });
  };

  // Fonction de gestion du changement de fichier
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  ///Save form data
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    setLoadingImport(true);

    try {
      const response = await CalendriersService.importExcel(formData, token);
      //console.log(response)
      if (response.status === 200) {
        setLoadingImport(false);
        fetchCalendriers();

        afficherMessagesSuccess(
          response.data.message ||
            "Bravo ! Votre enregistrement a été effectué avec succès."
        );

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setLoadingImport(false);
        afficherOneMessageError(
          response.data.message ||
            "Une erreur s'est produite lors de l'importation."
        );
      }
    } catch (error) {
      console.error("Une erreur s'est produite :", error);
      setLoadingImport(false);
      Swal.fire({
        icon: "error",
        text: "Une erreur s'est produite lors de l'importation.",
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    fetchCalendriers();
    $("#parametres-nav").addClass("show");
  }, []);

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">Calendriers </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-12">
                <h3 className="app-page-title mb-0">
                  <div className="d-flex align-items-center ms-2 gap-2">
                  Calendrier des audiences{" "}
                  <Link
                    className={`btn btn-sm btn-${
                      getButtonConfig("CREATE")?.color
                    }`}
                    to={myroutes.calendriers_create}
                    title="Ajouter"
                  >
                    <i className={`fa ${getButtonConfig("CREATE")?.icon}`}></i>
                  </Link>
                  <Link
                    className={`btn btn-sm btn-${
                      getButtonConfig("EDIT")?.color
                    }`}
                    to={myroutes.joursferies_index}
                    title="Liste Jours feriés"
                  >
                    <i className="bi bi-calendar-event"></i>&nbsp; Feriés
                  </Link>
                  <ExcelExport
                    fileName={"calendriers"}
                    data={calendriersToExport}
                  />
                  <div className="dropdown">
                    <button
                      className="btn btn-sm btn-success dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                     <i className="bi bi-upload"></i>&nbsp;Importer
                    </button>

                    <Form
                      className="dropdown-menu mb-3 p-4" style={{ minWidth: '400px' }}
                      onSubmit={handleSubmit}
                      encType="multipart/form-data"
                    >
                      <div className="d-flex align-items-center">
                        <input
                          type="file"
                          className="form-control border-success w-100"
                          accept=".xlsx, .xls"
                          name="file"
                          onChange={handleFileChange}
                          required
                        />
                        <ButtonWithSpinner
                          type="submit"
                          loading={loadingImport}
                          color={"success"}
                          title="Importer le calendrier"
                          className="ms-2"
                        >
                          <i className="bi bi-upload"></i>&nbsp;OK
                        </ButtonWithSpinner>
                      </div>
                    </Form>
                  </div>
                  </div>
                </h3>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table display nowrap">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="text-center">N°</th>
                        <th className="text-center">Chambre</th>
                        <th className="text-center">Jour</th>
                        <th className="text-center">Année judiciaire</th>
                        <th className="text-center">Statut</th>
                        <th className="text-center">Date modif.</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {calendriers.length > 0 &&
                        calendriers.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">
                              {item.get_chambre?.libelle}
                            </td>
                            <td className="text-left">
                              <span style={{ display: "none" }}>
                                [{dayjs(item.jour).format("YYYY-MM-DD")}] &nbsp;
                              </span>{" "}
                              {convertDateToTexte(item.jour)}
                            </td>
                            <td className="text-center">
                              {item.get_calendrier_global?.annee}
                            </td>
                            <td className="text-center">
                              <span
                                className={`badge ${
                                  item.get_statut.libelle === "ACTIF"
                                    ? "text-bg-success"
                                    : "text-bg-danger"
                                }`}
                              >
                                {item.get_statut.libelle}
                              </span>
                            </td>
                            <td className="text-center">
                              <span>
                                {dayjs(item.updated_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>
                            </td>
                            <td className="text-center">
                              <Button
                                className={`btn btn-sm btn-${
                                  getButtonConfig("EDIT")?.color
                                }`}
                                onClick={() => goToEdit(item.ref)}
                                title={`${getButtonConfig("EDIT")?.text}`}
                              >
                                <i
                                  className={`fa ${
                                    getButtonConfig("EDIT")?.icon
                                  }`}
                                ></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className={`btn btn-sm btn-${
                                  getButtonConfig("DELETE")?.color
                                }`}
                                onClick={() => onDelete(item.ref)}
                                title={`${getButtonConfig("DELETE")?.text}`}
                              >
                                <i
                                  className={`fa ${
                                    getButtonConfig("DELETE")?.icon
                                  }`}
                                ></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className="btn btn-sm btn-success"
                                onClick={() =>
                                  handleCopyReference(
                                    item.get_chambre?.libelle +
                                      " - " +
                                      convertDateToTexte(item.jour),
                                    item.id
                                  )
                                }
                              >
                                <i className="bi bi-clipboard2-fill"></i> Ref
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
