import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../../backoffice/admin/include/layout";
import $ from "jquery";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import {
  afficherMessagesSuccess,
  afficherOneMessageError,
  copyToClipboard,
  setDataTable,
} from "../../../../utils/helpers";
import parse from "html-react-parser";
import dayjs from "dayjs";
import TribunauxService from "../../../../services/TribunauxService";
import { Dialog } from "primereact/dialog";
import CreateFormTProcedures from "../../../../components/CreateFormTProcedures";
import EditFormTProcedures from "../../../../components/EditFormTProcedures";
import { getButtonConfig } from "../../../../utils/ButtonUtils";

export default function TribunauxIndex() {
  //##########################/// METHODES ##########################///
  const [tribunaux, setTribunaux] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [editRef, setEditRef] = useState("");

  const [formData, setFormData] = useState({});

  const handleCopyReference = (reference, itemId) => {
    copyToClipboard(reference);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  //get liste tribunaux
  const fetchTribunaux = async () => {
    try {
      const response = await TribunauxService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        setTribunaux(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête : ", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  const lauchEditForm = async (ref) => {
    //Lancer le modal d'edition
    try {
      const response = await TribunauxService.edit(ref, token);
      if (response.data) {
        const data = response.data.data;
        setEditData(data);
        setEditRef(ref);
        setShowEditForm(true);
      } else {
        console.log("Data not found in API response");
      }
    } catch (error) {
      console.error("API error", error);
      Swal.fire({
        icon: "error",
        text: error.message,
      });
      //setValidationError(error.erreur);
    }
  };

  ///Save form data
  const handleUpdate = async (e) => {
    e.preventDefault();
    setShowEditForm(false);
    await TribunauxService.update(editRef, editData, token)
      .then((response) => {
        if (response.data.status != true) {
          fetchTribunaux();
          Swal.fire({
            icon: "error",
            text: response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          //setValidationError(response.data.message)
        } else {
          //Actualiser les data
          fetchTribunaux();
          afficherMessagesSuccess("Modification effectuée avec succès");
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.message,
        });
      });
  };

  ///Save form data
  const handleAddSave = async (e) => {
    e.preventDefault();
    setShowAddForm(false);
    await TribunauxService.store(formData, token)
      .then((response) => {
        if (response.data.status == true) {
          fetchTribunaux();
          afficherMessagesSuccess(
            "Bravo ! Votre enregistrement a été efectué avec succès. "
          );
        } else {
          afficherOneMessageError(
            response.data.message + " " + response.data.data
          );
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.message,
        });
        console.log(error);
      });
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        TribunauxService.delete(param, token)
          .then((response) => {
            if (response.data.erreur) {
              Swal.fire({
                icon: "error",
                text: response.data.erreur,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              fetchTribunaux();
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.message,
            });
            console.log(e);
          });
      }
    });
  };

  useEffect(() => {
    fetchTribunaux();
    $("#parametres-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <BreadcrumbComponent
              params={{ isParametre: true, libelle: "Tribunaux" }}
              routeName={myroutes.tribunaux_index}
            ></BreadcrumbComponent>

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  Liste des tribunaux{" "}
                  <a
                    className={`btn btn-sm btn-${
                      getButtonConfig("CREATE")?.color
                    }`}
                    onClick={() => setShowAddForm(true)}
                    title="Ajouter"
                  >
                    <i className={`fa ${getButtonConfig("CREATE")?.icon}`}></i>
                    &nbsp;Ajouter
                  </a>{" "}
                </h3>
              </div>
            </div>

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font  table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Libelle</th>
                        <th className="">Code</th>
                        <th className="">Statut</th>
                        <th className="">Date modification</th>
                        <th className="">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {tribunaux.length > 0 &&
                        tribunaux.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell">{item.libelle}</td>
                            <td className="cell">
                              <span className="fw-bold">{item.code}</span>
                            </td>
                            <td className="cell">
                              <span
                                className={`badge ${
                                  item.get_statut?.libelle === "ACTIF"
                                    ? "text-bg-success"
                                    : "text-bg-danger"
                                }`}
                              >
                                {item.get_statut?.libelle}
                              </span>
                            </td>
                            <td className="cell">
                              <span>
                                {dayjs(item.updated_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>
                            </td>
                            <td className="cell">
                              <Button
                                className={`btn btn-sm btn-${
                                  getButtonConfig("EDIT")?.color
                                }`}
                                onClick={() => lauchEditForm(item.ref)}
                                title="Modifier"
                              >
                                <i
                                  className={`fa ${
                                    getButtonConfig("EDIT")?.icon
                                  }`}
                                ></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className={`btn btn-sm btn-${
                                  getButtonConfig("DELETE")?.color
                                }`}
                                onClick={() => onDelete(item.ref)}
                                title="Supprimer"
                              >
                                <i
                                  className={`fa ${
                                    getButtonConfig("DELETE")?.icon
                                  }`}
                                ></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className="btn btn-sm btn-success"
                                onClick={() =>
                                  handleCopyReference(item.code, item.id)
                                }
                              >
                                <i className="bi bi-clipboard2-fill"></i> Ref
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}

            {/****Mode d'edition */}
            <Dialog
              header="Modifier un tribunal"
              visible={showEditForm}
              onHide={() => setShowEditForm(false)}
            >
              <div className="app-card-body py-3">
                <Form
                  className="mx-3 settings-form"
                  style={{ textAlign: "left" }}
                  onSubmit={handleUpdate}
                >
                  <Row>
                    <Col md={6} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Code <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={handleEditChange}
                          value={editData.code}
                          placeholder=""
                          name="code"
                          aria-label="code"
                          maxLength={100}
                          minLength={2}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Libelle <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={handleEditChange}
                          value={editData.libelle}
                          placeholder=""
                          name="libelle"
                          aria-label="libelle"
                          maxLength={100}
                          minLength={2}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button type="submit" variant="warning me-3">
                    Enregistrer
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditForm(false)}
                  >
                    Annuler
                  </Button>
                </Form>
              </div>
            </Dialog>

            {/****Mode d'ajout */}
            <Dialog
              header="Enregistrer un nouveau tribunal"
              visible={showAddForm}
              onHide={() => setShowAddForm(false)}
            >
              <div className="app-card-body py-3">
                <Form
                  className="mx-3 settings-form"
                  style={{ textAlign: "left" }}
                  onSubmit={handleAddSave}
                >
                  <Row>
                    <Col md={6} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Code <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={handleChange}
                          value={formData.code}
                          placeholder=""
                          name="code"
                          aria-label="code"
                          maxLength={100}
                          minLength={2}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Libelle <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={handleChange}
                          value={formData.libelle}
                          placeholder=""
                          name="libelle"
                          aria-label="libelle"
                          maxLength={100}
                          minLength={2}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button type="submit" variant="warning me-3">
                    Enregistrer
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowAddForm(false)}
                  >
                    Annuler
                  </Button>
                </Form>
              </div>
            </Dialog>
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
