import http from "./http"

class PartiesdossiersService {
    index(token){
        return http.get("/api/v01/web/partiesdossiers/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    store(data, token){
        return http.post("/api/v01/web/partiesdossiers/store", data,{ headers: { 'Authorization': 'Bearer '+token } })
    }
    
    delete(ref, token){
        return http.delete(`/api/v01/web/partiesdossiers/${ref}/delete`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    storePartiesWithDossier(data, token){
        return http.post("/api/v01/web/parties/storePartiesWithDossier", data,{ headers: { 'Authorization': 'Bearer '+token } })
    }

}

export default new PartiesdossiersService()