import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // Plugin pour les interactions
import frLocale from "@fullcalendar/core/locales/fr";
import "@fullcalendar/bootstrap";
import "../styles/styles.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { myroutes } from "../routes/webroute";
import {
  afficherMessagesSuccess,
  afficherOneMessageError,
  getColorStatus,
} from "../utils/helpers";
import CalendriersService from "../services/CalendriersService";
import DatesaudiencesService from "../services/DatesaudiencesService";
import moment from "moment";
import Swal from "sweetalert2"; // Importation de SweetAlert2

const CalendarComponent = () => {
  const token = useSelector((state) => state.auth.token);
  const [dates_audiences, setDatesaudiences] = useState([]);
  const navigate = useNavigate();
  const profils = useSelector((state) => state.auth.profil);
  const calendarRef = useRef(null);

  const fetchCalendrier = async () => {
    try {
      const response = await CalendriersService.listeByProfil(token);
      const data = response.data.data.flatMap((item) => {
        const date = new Date(item.jour).toISOString().split("T")[0];

        if (item.get_dates_audiences && item.get_dates_audiences.length > 0) {
          return item.get_dates_audiences.flatMap((option) => {
            // Vérifier s'il y a un paiement avec le statut 'SUCCESS'
            const paiementsSuccess = option.get_dossier?.get_paiement?.filter(
              (paiement) => paiement.statut_transaction === "SUCCESS"
            );

            if (paiementsSuccess && paiementsSuccess.length > 0) {
              const creneau_debut = option.creneau_debut
                ? moment(option.creneau_debut).format("YYYY-MM-DD HH:mm:ss")
                : null;
              const creneau_fin = option.creneau_fin
                ? moment(option.creneau_fin).format("YYYY-MM-DD HH:mm:ss")
                : null;

              return {
                title: option.get_dossier
                  ? option.get_dossier.reference_dossier
                  : "Aucun dossier",
                start: creneau_debut || date,
                end: creneau_fin,
                allDay: !creneau_debut, // Si pas de créneau, définir l'événement comme "all day"
                refDossier: option.get_dossier ? option.get_dossier.ref : null,
                refAudience: option.ref,
                statutDossierId: option.get_dossier
                  ? option.get_dossier.statut_dossier_id
                  : "",
                displayTime: creneau_debut
                  ? moment(creneau_debut).format("HH:mm")
                  : null,
                hoursEnd: creneau_fin
                  ? moment(creneau_fin).format("HH:mm")
                  : null, // Ajouter l'heure de fin si disponible
              };
            } else {
              // Pas de paiements avec statut SUCCESS pour cette audience
              return [];
            }
          });
        } else {
          // Pas de dates d'audience pour cet élément
          return [
            {
              title: "Aucun dossier",
              start: date,
              allDay: true,
              refDossier: null,
              refAudience: null,
              statutDossierId: null,
              displayTime: null,
              hoursEnd: null,
            },
          ];
        }
      });

      setDatesaudiences(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEventClick = (arg) => {
    if (
      profils.includes("ADMIN") ||
      profils.includes("SUPERADMIN") ||
      profils.includes("GEC") ||
      profils.includes("AGEC") ||
      profils.includes("GCH") ||
      profils.includes("CCC")
    ) {
      let ref = arg.event._def.extendedProps.refDossier;
      ref != null && navigate(`${myroutes.dossiers_show}/${ref}`);
    } else {
      afficherOneMessageError(
        "Vous n'êtes pas autorisé à afficher les détails de ce dossier."
      );
    }
  };

  //Passer en mode JOUR quand on clique sur la date
  const handleDateSelect = (arg) => {
    if (
      !profils.includes("ADMIN") &&
      !profils.includes("SUPERADMIN") &&
      !profils.includes("GEC") &&
      !profils.includes("AGEC") &&
      !profils.includes("GCH")
    ) {
      afficherOneMessageError(
        "Vous n'êtes pas autorisé à effectuer cette opération."
      );
      return;
    }

    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView("timeGridDay", arg.startStr);
  };

  const handleEventDrop = async (info) => {
    if (
      !profils.includes("ADMIN") &&
      !profils.includes("SUPERADMIN") &&
      !profils.includes("GEC") &&
      !profils.includes("AGEC") &&
      !profils.includes("GCH")
    ) {
      afficherOneMessageError(
        "Vous n'êtes pas autorisé à effectuer cette opération."
      );
      info.revert();
      return;
    }

    if (info.event.title === "Aucun dossier") {
      info.revert();
      return;
    }

    const formattedDate = moment(info.event.start).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const refAudience = info.event._def.extendedProps.refAudience;
    const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
    // Comparez les deux dates
    if (moment(formattedDate).isBefore(currentDate)) {
      afficherOneMessageError(
        "Vous ne pouvez plus modifier le créneau d'un dossier dont la date est déjà passée."
      );
      info.revert();
      return;
    }

    // Afficher un popup pour renseigner l'heure de fin
    const { value: endTime } = await Swal.fire({
      title: "Renseigner l'heure de fin",
      html: `
        <div>
          <p>Heure de début: ${moment(info.event.start).format("HH:mm")}</p>
          <input type="time" id="end-time" class="swal2-input" min="${moment(
            info.event.start
          ).format("HH:mm")}" required>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler",
      preConfirm: () => {
        const endTimeInput = Swal.getPopup().querySelector("#end-time").value;
        if (!endTimeInput) {
          Swal.showValidationMessage(`Veuillez renseigner l'heure de fin`);
        }

        const startTime = moment(info.event.start).format("HH:mm");
        if (endTimeInput <= startTime) {
          Swal.showValidationMessage(
            `L'heure de fin doit être supérieure à l'heure de début`
          );
        }
        return endTimeInput;
      },
    });

    if (endTime) {
      const endDateTime =
        moment(info.event.start).format("YYYY-MM-DD") + " " + endTime + ":00";

      try {
        const response = await DatesaudiencesService.updateCreneau(
          token,
          refAudience,
          {
            creneau_debut: formattedDate,
            creneau_fin: endDateTime,
          }
        );
        if (response.data.status === true && response.status === 200) {
          afficherMessagesSuccess(response.data.message);
          //fetchCalendrier();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          afficherOneMessageError(response.data.message);
        }
      } catch (error) {
        console.log(error);
        afficherOneMessageError(error.response.data.message);
        info.revert();
      }
    } else {
      info.revert();
    }
  };

  const handleEventResize = async (info) => {
    if (
      !profils.includes("ADMIN") &&
      !profils.includes("SUPERADMIN") &&
      !profils.includes("GEC") &&
      !profils.includes("AGEC") &&
      !profils.includes("GCH")
    ) {
      afficherOneMessageError(
        "Vous n'êtes pas autorisé à effectuer cette opération."
      );
      return;
    }

    const startDateTime = moment(info.event.start).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const endDateTime = moment(info.event.end).format("YYYY-MM-DD HH:mm:ss");
    const refAudience = info.event._def.extendedProps.refAudience;

    try {
      const response = await DatesaudiencesService.updateCreneau(
        token,
        refAudience,
        {
          creneau_debut: startDateTime,
          creneau_fin: endDateTime,
        }
      );
      if (response.data.status === true && response.status === 200) {
        afficherMessagesSuccess(response.data.message);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        afficherOneMessageError(response.data.message);
      }
    } catch (error) {
      console.log(error);
      afficherOneMessageError(error.response.data.message);
      info.revert();
    }
  };

  useEffect(() => {
    fetchCalendrier();
  }, []);

  return (
    <div className="calendar-container">
      <FullCalendar
        ref={calendarRef}
        dayMaxEventRows={5} // Définit le nombre d'événements visible directement par jour
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale={frLocale}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        buttonText={{
          today: "Aujourd'hui",
          month: "Mois",
          week: "Semaine",
          day: "Jour",
        }}
        eventClick={handleEventClick}
        selectable={true}
        select={handleDateSelect}
        events={dates_audiences}
        editable={true} // Permet de rendre les événements éditables (déplaçables)
        droppable={true} // Permet de rendre le calendrier acceptant les drops
        eventDrop={handleEventDrop} // Gestionnaire pour les drops d'événements
        eventResize={handleEventResize} // Gestionnaire pour les redimensionnements d'événements
        themeSystem="bootstrap"
        eventColor="#fff"
        slotMinTime="08:00:00" // Définir l'heure de début à 8h
        slotMaxTime="18:00:00" // Définir l'heure de fin à 18h
        slotDuration="00:05:00" // Définir la durée de chaque créneau à 5 minutes
        slotLabelInterval="00:05:00" // Afficher une étiquette de temps toutes les 5 minutes
        eventContent={(arg) => {
          const isNoDossier = arg.event.title === "Aucun dossier";
          const backgroundColor = isNoDossier
            ? "gray"
            : getColorStatus(arg.event.extendedProps.statutDossierId);
          const time = arg.event.extendedProps.displayTime
            ? ` ${arg.event.extendedProps.displayTime}`
            : "";
          const hoursEnd = arg.event.extendedProps.hoursEnd
            ? ` - ${arg.event.extendedProps.hoursEnd}`
            : "";

          return (
            <div
              style={{
                backgroundColor,
                color: "white",
                overflow: "visible",
                whiteSpace: "normal",
                height: "auto",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
              }}
            >
              {arg.event.title} &nbsp;&nbsp;
              <strong>
                {time}
                {hoursEnd}
              </strong>
            </div>
          );
        }}
      />
    </div>
  );
};

export default CalendarComponent;
