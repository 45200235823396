import http from "./http";

class DossiersService {
    index(token) {
        return http.get("/api/v01/web/dossiers/index", { headers: { 'Authorization': 'Bearer ' + token } })
    }

    selectfiles(id, token) {
        return http.get(`/api/v01/web/dossierspartiesdocuments/${id}/file`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    store(data, token) {
        return http.post("/api/v01/web/dossiers/store", data, { headers: { 'Authorization': 'Bearer ' + token, "Content-Type": "multipart/form-data" } })
    }

    edit(ref, token) {
        return http.get(`/api/v01/web/dossiers/${ref}/edit`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    show(ref, token) {
        return http.get(`/api/v01/web/dossiers/${ref}/show`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    update(ref, data, token) {
        return http.put(`/api/v01/web/dossiers/${ref}/update`, data, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    delete(ref, token) {
        return http.delete(`/api/v01/web/dossiers/${ref}/delete`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    /*edit(id, token) {
        return http.get(`/api/v01/web/dossiers/${id}/edit`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    update(data, ref, token) {
        return http.put(`/api/v01/web/dossiers/${ref}/update`, data, { headers: { 'Authorization': 'Bearer ' + token } })
    }*/

    countDossiersByStatut(token) {
        return http.get("/api/v01/web/dossiers/count_dossiersbystatut", { headers: { 'Authorization': 'Bearer ' + token } })
    }

    countDossiersByChambre(token) {
        return http.get("/api/v01/web/dossiers/dossiers_by_chambre", { headers: { 'Authorization': 'Bearer ' + token } })
    }

    findByReference(ref_dossier, token) {
        return http.get(`/api/v01/web/dossiers/${ref_dossier}/find_by_reference`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    get_stats(token) {
        return http.get("/api/v01/web/dossiers/count_by_month", { headers: { 'Authorization': 'Bearer ' + token } })
    }

    listeDossierPayer(token) {
        return http.get("/api/v01/web/dossiers/liste/payer", { headers: { 'Authorization': 'Bearer ' + token } })
    }

    sendDecisionNumerique(data, token) {
        return http.post("/api/v01/web/decisionsnumeriques/store", data, { headers: { 'Authorization': 'Bearer ' + token, "Content-Type": "multipart/form-data" } })
    }

    handlePutPrint(id, data, token) {
        return http.post(`/api/v01/web/dossiers/imprime/${id}`, data, { headers: { 'Authorization': 'Bearer ' + token, "Content-Type": "multipart/form-data" } })
    }

    jonctionDossier(data, token) {
        return http.post(`/api/v01/web/jonctionsdossiers/store`, data, { headers: { 'Authorization': 'Bearer ' + token } })
    }
}

export default new DossiersService()