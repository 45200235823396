import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import Swal from "sweetalert2";
import { logoutRequest } from "../../auth/logout";
import { resetAuthData } from "../../../app/providers/authSlice";

export default function SideBar() {
  const auth = useSelector((state) => state.auth);
  const profils = useSelector((state) => state.auth.profil);

  const hasAccessToSensitiveInfo = profils.includes("USER");
  const hasAccessToSensitiveInfoActeur = profils.includes("AJ");

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <React.Fragment>
      {/* ======= Sidebar ======= */}
      <aside id="sidebar" className="sidebar" style={{ textTransform: "" }}>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                currentPath === myroutes.dashboard ? "active" : ""
              }`}
              to={
                hasAccessToSensitiveInfo
                  ? myroutes.dashboard_newuser
                  : myroutes.dashboard_acteur
              }
            >
              <i className="bi bi-speedometer"></i>
              <span>Tableau de bord</span>
            </Link>
          </li>

          {/* End Dashboard Nav */}

          {hasAccessToSensitiveInfoActeur && (
            <>
              <li className="nav-item">
                <Link
                  className={`${
                    currentPath == myroutes.dossiersacteurs_index && "active"
                  } nav-link collapsed`}
                  to={myroutes.dossiersacteurs_index}
                >
                  <i className="bi bi-folder"></i>
                  <span>Mes dossiers</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    currentPath == myroutes.dossiersacteurs_creer && "active"
                  } nav-link collapsed`}
                  to={myroutes.dossiersacteurs_creer}
                >
                  <i className="bi bi-plus-circle"></i>
                  <span>Enrôler un dossier</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link collapsed"
                  to={myroutes.demande_arret_acteur_index}
                >
                  <i className="bi bi-envelope-paper"></i>
                  <span>Mes demandes (copie ou grosse)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link collapsed"
                  to={myroutes.demande_arret}
                  target="_blank"
                >
                  <i className="bi bi-pencil-square"></i>
                  <span>Faire une demande</span>
                </Link>
              </li>
              
            </>
          )}

          {profils.includes("AGEC") || profils.includes("APDT") ? (
            <li className="nav-item">
              <Link
                className="nav-link collapsed"
                data-bs-target="#dossiers-nav"
                data-bs-toggle="collapse"
              >
                <i className="bi bi-journal-text"></i>
                <span>Dossiers</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </Link>
              <ul
                id="dossiers-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to={myroutes.dossiersacteurs_index}>
                    <i className="bi bi-circle"></i>
                    <span>Mes dossiers</span>
                  </Link>
                </li>
              </ul>
            </li>
          ) : null}

          {/***
           * {!profils.includes("USER") && (
            <li className="nav-item">
              <Link
                className="nav-link collapsed" to={myroutes.demande_arret_create}>
                <i className="bi bi-envelope-paper"></i>
                <span>Demande de copie ou grosse</span>
              </Link>
            </li>
          )}
           */}

          {/* End Forms Nav */}
        </ul>
      </aside>
      {/* End Sidebar*/}
    </React.Fragment>
  );
}
