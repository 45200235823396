import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import $ from "jquery";
//import 'datatables.net-bs5/js/dataTables.bootstrap5.min.mjs'
//import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import DemandeArretsService from "../../../services/DemandeArretsService";
import {
  afficherMessagesErreur,
  afficherMessagesSuccess,
  afficherOneMessageError,
  setDataTable,
} from "../../../utils/helpers";
import { Dialog } from "primereact/dialog";
import { app_url } from "../../../services/http";
import { Editor } from "@tinymce/tinymce-react";
import LayoutActeur from "../inc/layout_acteur";

export default function DemandesArretsActeurIndex() {
  //##########################/// METHODES ##########################///
  const [statuts, setStatuts] = useState([]);
  const [pays, setPays] = useState([]);
  const [demandes_arrets, setDemandesArrets] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [detailLabel, setDetailLabel] = useState("");
  const [statut_id, setStatut_id] = useState("");
  const [titreModal, setTitreModal] = useState("");
  const [allstatuts, setAllStatuts] = useState([]);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const handleCloseAnswerModal = () => setShowAnswerModal(false);
  const [objet, setObjet] = useState(
    "Soumission des preuves de paiement de la demande de copie d'arrêt ou grosse"
  );
  const [fichier_impot, setFichierImpot] = useState("");
  const [fichier_paiement, setFichierPaiement] = useState("");
  const [demande_arret_id, setDemandeArretId] = useState("");
  const [piecesItem, setPiecesItem] = useState("");

  const [showPiecesModal, setShowPiecesModal] = useState(false);
  const handleClosePiecesModal = () => setShowPiecesModal(false);

  const [reference_dgi, setReferenceDGI] = useState("");
  const [reference_paiement, setReferencePaiement] = useState("");

  const [dossierData, setDossierData] = useState({
    demandes_en_cours: 0,
    demandes_traitees: 0,
    demandes_remises: 0,
  });

  //get liste statuts
  const fetchStatuts = async () => {
    try {
      const response = await StatutsService.index(token);
      const liste_statuts = response.data.data;
      const filteredData = liste_statuts.filter(
        (option) =>
          option.libelle == "TRAITÉ" ||
          option.libelle == "REMIS" ||
          option.libelle == "EN COURS"
      );

      setStatuts(filteredData);
      setAllStatuts(filteredData);
    } catch (error) {
      console.error("Erreur lors de la récupération  :", error);
    }
  };

  const getStatuts = (statut_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Statut = statuts.find((item) => {
      return item.id == idToFind;
    });

    return Statut ? Statut.libelle : "N/A";
  };

  //get liste demandes arrets
  const fetchDemandesArrets = async () => {
    try {
      const response = await DemandeArretsService.findByActeur(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDemandesArrets(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  const fetchDossierStats = async () => {
    try {
      const response = await DemandeArretsService.countDossiersByStatut(token);
      const responseData = response.data.data;

      setDossierData({
        //nouveaudossiers: responseData.nouveaudossiers,
        demandes_en_cours: responseData.demandes_en_cours,
        demandes_traitees: responseData.demandes_traitees,
        demandes_remises: responseData.demandes_remises,
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des statistiques :", error);
    }
  };



  const handleShowAnswerModal = (id, titre) => {
    setShowAnswerModal(true);
    setTitreModal(titre);
    setDemandeArretId(id);
  };

  // Fonction de gestion du changement de fichier
  const handleFileImpotChange = (e) => {
    setFichierImpot(e.target.files[0]);
  };

  const handleFilePaiementChange = (e) => {
    setFichierPaiement(e.target.files[0]);
  };

  //envoie de la demande de liquidation
  const handleSendAnswer = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("path_fichier_impot", fichier_impot);
    formData.append("demande_arret_id", demande_arret_id);
    formData.append("path_fichier_paiement", fichier_paiement);
    formData.append("objet", objet);
    formData.append("reference_dgi", reference_dgi);
    formData.append("reference_paiement", reference_paiement);

    try {
      const response = await DemandeArretsService.sendPreuves(
        formData,
        token
      );
      if (response.status === 200) {
        setShowAnswerModal(false);
        fetchDemandesArrets();

        afficherMessagesSuccess(
          response.data.message ||
            "Bravo ! Votre opération a été effectuée avec succès."
        );
      } else {
        setShowAnswerModal(false);
        afficherOneMessageError(
          response.data.message ||
            "Une erreur s'est produite lors de l'importation."
        );
      }
    } catch (error) {
      //console.error("Une erreur s'est produite :", error);
      setShowAnswerModal(false);

      afficherMessagesErreur(error.response.data);
    }
  };

  const handleShowPiecesModal = (param) => {
    setShowPiecesModal(true);
    setTitreModal(param.num_arret);
    setDemandeArretId(param.id);
    setPiecesItem(param);
  };

  useEffect(() => {
    fetchDemandesArrets();
    fetchStatuts();
    //fetchDossierStats();
    $("#submenu-4").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <LayoutActeur>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard_acteur}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Demandes de copie d'arrêts
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            
            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  Liste des demandes de copie d'arrêt ou grosse
                </h3>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Requérant</th>
                        <th className="">Reference dossier</th>
                        <th className="">Num. Arrêt</th>
                        <th className="">Nature affaire</th>
                        <th className="">Statut</th>
                        <th className="">Date</th>
                        <th className="">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {demandes_arrets.length > 0 &&
                        demandes_arrets.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell">
                              {item.nom + " " + item.prenoms}
                              <br />
                              {item.email}
                              <br />
                              {item.telephone}
                            </td>
                            <td className="cell">
                            {item.get_dossier
                                ? item.get_dossier.reference_dossier
                                : item.reference_manuelle}
                            </td>
                            <td className="cell">{item.num_arret}</td>
                            <td className="cell">
                              <span className="text-truncate">
                                {item.get_nature_affaire?.libelle}
                              </span>
                            </td>
                            <td className="cell">
                              <span
                                className={
                                  getStatuts(item.statut_id) == "EN COURS"
                                    ? "badge bg-danger"
                                    : getStatuts(item.statut_id) == "REMIS"
                                    ? "badge bg-primary"
                                    : "badge bg-success"
                                }
                              >
                                {getStatuts(item.statut_id)}
                              </span>
                            </td>
                            <td className="cell">
                              <span>
                                {dayjs(item.created_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>
                            </td>
                            <td className="cell">
                              <div className="d-flex">
                              {item.fiche_liquidation && 
                                  <>
                                    <Button
                                      className="btn btn-sm btn-success"
                                      onClick={() =>
                                        handleShowAnswerModal(
                                          item.id,
                                          item.num_arret
                                        )
                                      }
                                      title="Répondre à la demande"
                                    >
                                      Répondre
                                    </Button>
                                    &nbsp;
                                  </>
                                 }
                                 {/**: (
                                  
                                    <Button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => handleShowPiecesModal(item)}
                                    title="Ajouter les pièces de la demande"
                                  >
                                    Pièces
                                  </Button> 
                                */}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}

                {/* Modal réponse*/}
                <Dialog
                  header={titreModal}
                  visible={showAnswerModal}
                  onHide={handleCloseAnswerModal}
                  style={{
                    width: "700px",
                    borderBottom: "1px solid grey",
                    color: "GrayText",
                  }}
                  headerStyle={{ background: "#f0f0f0" }}
                >
                  <div className="p-fluid">
                    <div className="row">
                      <hr />
                      <p className="text-center fw-bold">
                        Soumettre les preuves de paiement
                      </p>
                    </div>
                  </div>
                  <div className="row">
                  <Col md={12} sm={12} className="form-group">
                      <Form.Group>
                        <Form.Label className="fw-bold">
                          Objet <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border border rounded-2 control-label"
                          placeholder="Objet"
                          name="objet"
                          aria-label="objet"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          value={objet}
                          onChange={setObjet}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group>
                        <Form.Label className="fw-bold">
                          Référence DGI <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border border rounded-2 control-label"
                          placeholder=""
                          name="reference_dgi"
                          aria-label="reference_dgi"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          value={reference_dgi}
                          onChange={setReferenceDGI}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group>
                        <Form.Label className="fw-bold">
                        Référence paiement <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border border rounded-2 control-label"
                          placeholder=""
                          name="reference_paiement"
                          aria-label="reference_paiement"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          value={reference_paiement}
                          onChange={setReferencePaiement}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Fiche de paiement DGI <i className="text-danger">*</i>
                        </Form.Label>
                        <input
                          type="file"
                          className="form-control border-success"
                          accept=".pdf"
                          name="fichier_impot"
                          onChange={handleFileImpotChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Quittance de paiement <i className="text-danger">*</i>
                        </Form.Label>
                        <input
                          type="file"
                          className="form-control border-success"
                          accept=".pdf"
                          name="fichier_paiement"
                          onChange={handleFilePaiementChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </div>

                  <div className="p-dialog-footerx mt-4">
                    {/* Pied de page du modal */}
                    <Button
                      type="submit"
                      variant="warning me-3"
                      onClick={handleSendAnswer}
                    >
                      Envoyer
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={handleCloseAnswerModal}
                    >
                      Annuler
                    </Button>
                  </div>
                </Dialog>

                {/* Modal preuve*/}
                <Dialog
                  header={"Détails sur la demande " + titreModal}
                  maximizable
                  visible={showPiecesModal}
                  onHide={handleClosePiecesModal}
                  style={{
                    width: "700px",
                    borderBottom: "1px solid grey",
                    color: "GrayText",
                  }}
                  headerStyle={{ background: "#f0f0f0" }}
                >
                  <div className="p-fluid">
                    <div className="row">
                      <hr />
                      <p className="text-center fw-bold">
                        Les pièces de la demande
                      </p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label htmlFor="">Fiche de liquidation</label>
                    <iframe
                      src={`${app_url}/${piecesItem.fiche_liquidation}`}
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                      title="Liquidation Document"
                    />
                  </div>
                  <div className="row">
                    <label htmlFor="">Fichier de paiement DGI</label>
                    <iframe
                      src={`${app_url}/${piecesItem.path_fichier_impot}`}
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                      title="Liquidation Document"
                    />
                  </div>
                  <div className="row">
                    <label htmlFor="">Preuve de paiement</label>
                    <iframe
                      src={`${app_url}/${piecesItem.path_fichier_paiement}`}
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                      title="Liquidation Document"
                    />
                  </div>

                  <div className="p-dialog-footerx mt-4">
                    <Button
                      variant="secondary"
                      onClick={handleClosePiecesModal}
                    >
                      Fermer
                    </Button>
                  </div>
                </Dialog>
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </LayoutActeur>
  );
}
