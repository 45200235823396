import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { myroutes } from "../../routes/webroute";
import Swal from "sweetalert2";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, resetAuthData } from "../../app/providers/authSlice";
import AuthService from "../../services/AuthService";
import { Tab, Nav } from "react-bootstrap";
import "../../styles/styles.css";
//import { height } from "@mui/system";
import validator from "email-validator";
import { logoutRequest } from "./logout";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function LoginForDemandes() {
  ///############### METHODES #############################//
  const [key, setKey] = useState("A"); // Onglet par défaut
  //const [loadingSubmitA, setLoadingSubmitA] = useState(false);
  const [loadingSubmitB, setLoadingSubmitB] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isBrowser = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();

  //const isAuthenticate = useSelector((state) => state.auth.isAuthenticate);

  const auth = useSelector((state) => state.auth);

  //const profils = useSelector((state) => state.auth.profil);
  //const profil_habilitation = useSelector((state) => state.auth.profil_habilitation);

  //const roleUtilisateurAdmin = profils.includes("ADMIN")
  //const roleUtilisateurActeur = profils.includes("AJ")

  //const checkStatut = { ADMIN: "ADMIN", AJ: "AJ"};

  //const token = useSelector((state) => state.token);

  //const [passwordStrength, setPasswordStrength] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //const [showField, setShowField] = useState(false);

  //console.log(token)
  //Creation des ascesseurs getters et setters pour tout le formulaire
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginRequest, setLoginRequest] = useState({ email: "", password: "" });
  //const [rolesusers, setRolesusers] = useState([])

  const [rememberMe, setRememberMe] = useState(false);

  /*const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };*/

  const [isValidEmail, setIsValidEmail] = useState(true);

  // end vérification de l'authentification
  //dispatch(checkAuthenticate());
  /*if (isAuthenticate) {
    //  navigate(myroutes.dashboard)
  }*/

  const onInputChange = (name, e) => {
    const val = (e.target && e.target.value) || "";

    let _formRequest = { ...loginRequest };
    _formRequest[`${name}`] = val;
    setLoginRequest(_formRequest);

    // Validation de l'e-mail avec email-validator
    if (name === "email") {
      setIsValidEmail(validator.validate(val));
    }
  };



  const handleLoginB = async (e) => {
    e.preventDefault();
    setLoadingSubmitB(true);

    // Sauvegarder les identifiants si "Se rappeler de moi" est coché
    if (rememberMe) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    } else {
      // Si "Se rappeler de moi" n'est pas coché, supprimez les identifiants sauvegardés
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }

    try {
      // Connexion de l'utilisateur
      const response = await AuthService.login(loginRequest);

      // Vérifier si la connexion a réussi
      if (response && response.data && response.data.status) {
        const data = response.data;
        // Transmission des données au reducer
        dispatch(loginUser(data));

        // Récupérer les profils de l'utilisateur
        const profils = data.profil;
        // alert(JSON.stringify(profils))

        // Vérifier si l'un des profils de l'utilisateur contient "AJ"
        const hasActeurRole = profils.some((profil) => profil.code === "AJ");

        // Si l'utilisateur a le rôle
        if (hasActeurRole) {
          // Naviguer vers le dashboard
          if (location.state != null) {
            navigate(myroutes.demande_arret);
            Swal.fire({
              icon: "success",
              title: "Félicitations !!!",
              text: "Vous pouvez procéder au paiement",
              showConfirmButton: true,
              timer: 3000,
            });
          } else {
            navigate(myroutes.demande_arret);
          }
        } else {
          // Afficher un message d'erreur et déconnecter l'utilisateur
          Swal.fire({
            icon: "error",
            title: "Désolé(e) !!!",
            text: "Cet espace est réservé aux Acteurs de Justice.",
            showConfirmButton: true,
            timer: 3000,
          });
          handleLogout();
        }
      } else {
        // Gérer les erreurs de connexion
        Swal.fire({
          icon: "error",
          text: response.data.message,
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          background: "red",
          color: "white",
          iconColor: "white",
        });
      }
    } catch (error) {
      // Gérer les erreurs
      console.error("Erreur lors de la requête de connexion", error);
    }
    setLoadingSubmitB(false);
  };

  // Effacer la force du mot de passe lorsque le mot de passe change
  /* useEffect(() => {
    
    setPasswordStrength(getPasswordStrength());  // Ajoutez cet appel
  }, [loginRequest.password]);*/

  // se déconnecter
  const handleLogout = () => {
    // Déconnexion de l'utilisateur sans afficher la question de confirmation
    logoutRequest(auth.token);
    dispatch(resetAuthData());
    // navigate(myroutes.login);
  };

  const handleBack = () => {
    navigate(myroutes.login);
  }

  useEffect(() => {
    // Vérifier si les identifiants sont sauvegardés dans le stockage local
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");

    // Remplir automatiquement les champs du formulaire s'ils existent
    if (savedEmail) {
      setEmail(savedEmail);
    }

    if (savedPassword) {
      setPassword(savedPassword);
    }
  }, []);
  ///############### END METHODES #############################//

  return (
    <React.Fragment>
      <section className="bg-login py-md-5 py-xl-8">
        <div className="container position-absolute top-50 start-50 translate-middle">
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-md-6 col-xl-5 mx-auto">
              
              <div className="card border-0 rounded-4">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <div className="row">
                    <div className="col-12"></div>
                  </div>
                  <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                    <Nav variant="tabs" className="custom-tabs">
                      <Nav.Item className="w-100">
                        <Nav.Link eventKey="A">
                          <h4>
                            <i className="fas fa-lock"></i> &nbsp;Espace avocats
                          </h4>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="A">
                        <div className="my-3 p-2 text-center">
                          
                        </div>
                        {/* Formulaire pour l'onglet B */}
                        <form
                          className="auth-form login-form"
                          onSubmit={handleLoginB}
                        >
                          <div className="email mb-4">
                            <label
                              className="sr-only fw-bold mb-2"
                              htmlFor="signin-email1"
                            >
                              Identifiant (email){" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              id="signin-email1"
                              value={loginRequest.email}
                              minLength={3}
                              maxLength={100}
                              name="signin-email1"
                              onChange={(e) => onInputChange("email", e)}
                              type="email"
                              className={`form-control signin-email border ${
                                isValidEmail
                                  ? "border-border-secondary"
                                  : "border-danger"
                              }`}
                              placeholder="Email"
                              required
                            />
                            {!isValidEmail && (
                              <p className="text-danger mt-1">
                                Adresse e-mail invalide. Veuillez entrer une
                                adresse e-mail valide.
                              </p>
                            )}
                          </div>
                          {/*//form-group*/}
                          <div className="password mb-3">
                            <label
                              className="sr-only fw-bold mb-2"
                              htmlFor="signin-password"
                            >
                              Mot de passe{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group">
                              <input
                                style={{}}
                                id="signin-password2"
                                value={loginRequest.password}
                                minLength={3}
                                maxLength={50}
                                name="signin-password2"
                                type={showPassword ? "text" : "password"}
                                onChange={(e) => onInputChange("password", e)}
                                className="form-control signin-password border border-border-secondary"
                                placeholder="Mot de passe"
                                required="required"
                              />
                              <button
                                type="button"
                                className="password-toggle-button btn btn-outline-secondary"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? <FiEyeOff /> : <FiEye />}
                              </button>
                            </div>
                          </div>
                          {/*//form-group*/}
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="remember"
                              value="true"
                              id="rememberMe2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberMe2"
                            >
                              Se rappeler de moi
                            </label>
                            <Link
                              className="text-dark"
                              to={myroutes.forgot_password}
                              style={{ float: "right" }}
                            >
                              Mot de passe oublié ?
                            </Link>
                          </div>

                          <div className="text-center">
                            <div className="row">
                              <div className="col-6">
                                <ButtonWithSpinner
                                  loading={loadingSubmitB}
                                  type="submit"
                                  style={{ fontSize: "18px" }}
                                  className="btn app-btn-dark bg-primary  text-white w-100 mx-auto fw-bold"
                                >
                                  Se connecter
                                </ButtonWithSpinner>
                              </div>

                              <div className="col-6">
                                <ButtonWithSpinner
                                  type="button"
                                  onClick={handleBack}
                                  style={{ fontSize: "18px" }}
                                  className="btn app-btn-dark bg-secondary text-white w-100 mx-auto fw-bold"
                                >
                                  Annuler
                                </ButtonWithSpinner>
                              </div>
                            </div>
                          </div>

                          <br />
                        </form>
                        {/* ... */}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-login">
        <div className="col-md-12 d-flex w-100 ">
          <div
            className="col-md-4"
            style={{ height: "8px", backgroundColor: "#008559" }}
          ></div>
          <div
            className="col-md-4"
            style={{ height: "8px", backgroundColor: "#FFD400" }}
          ></div>
          <div
            className="col-md-4"
            style={{ height: "8px", backgroundColor: "#E43009" }}
          ></div>
        </div>
        <div className="container text-center py-2">
          <small className="copyright">
            &copy;{new Date().getFullYear()} COUR D'APPEL DE COMMERCE - COTONOU
            | Tous droits réservés.
            {/*
              Conception & Réalisation :{" "}
              <a className="app-link" href="www.digiweb.bj" target="_blank">
                DigiWeb SARL
              </a>
              */}
          </small>
        </div>
      </section>
    </React.Fragment>
  );
}
